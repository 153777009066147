import { Trans, useTranslation } from "react-i18next";
import { Form, FormInstance, Progress, Upload, UploadProps } from "antd";
import { ConfigStates } from "../../../../utils/types";
import { ReactComponent as TransferFilled } from "../../../../assets/icons/transferFilled.svg";
import { ReactComponent as UploadFilled } from "../../../../assets/icons/codeUploadTwoTone.svg";

import Input from "antd/lib/input";
import RetailFormInput from "../../RetailFormInput";
import RetailMainButton from "../../../Button/RetailMainButton";
import RetailSelect from "../../../Select/RetailSelect";
import RetailText from "../../../Typography/RetailText";

import cm from "./style.module.scss";

interface RetailConfigurationsRowProps {
  configStates: ConfigStates;
  setConfigStates: React.Dispatch<React.SetStateAction<ConfigStates>>;
  isActive: boolean;
  selected: string | null;
  form: FormInstance;
}

const RetailConfigurationsRow = ({
  configStates,
  setConfigStates,
  isActive,
  selected,
  form,
}: RetailConfigurationsRowProps) => {
  const { t } = useTranslation();

  const options = [
    { label: t("pages.admin.console.minutes_15"), value: "15_MINUTES" },
    { label: t("pages.admin.console.minutes_30"), value: "30_MINUTES" },
    { label: t("pages.admin.console.hour_1"), value: "1_HOUR" },
    { label: t("pages.admin.console.hours_2"), value: "2_HOURS" },
    { label: t("pages.admin.console.hours_3"), value: "3_HOURS" },
    { label: t("pages.admin.console.hours_4"), value: "4_HOURS" },
    { label: t("pages.admin.console.hours_6"), value: "6_HOURS" },
    { label: t("pages.admin.console.hours_12"), value: "12_HOURS" },
    { label: t("pages.admin.console.hours_24"), value: "24_HOURS" },
  ];

  const isValidURL =
    /^(https?:\/\/)?([\w\u0600-\u06FF-]+\.)*[\w\u0600-\u06FF-]{2,}\.[a-z\u0600-\u06FF]{2,}(\/[\w\u0600-\u06FF-.,@?^=%&:/~+#]*)?$/;

  const props: UploadProps = {
    name: "files",
    multiple: false,
    className: `dragger ${cm.dragger}`,
    beforeUpload: () => false,
    maxCount: 1,
    /*   itemRender: () => <></>, */
    onChange: (f) =>
      f.file.status !== "removed" &&
      setConfigStates((prev) => ({ ...prev, file: f.file })),
    onRemove: () => setConfigStates((prev) => ({ ...prev, file: null })),
    accept: ".xml",
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setConfigStates((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelect = (value: string) =>
    setConfigStates((prev) => ({ ...prev, cycle: value }));

  const switchDisabled = () => {
    switch (selected) {
      case "googleFeedUrl":
      case "metaFeedUrl":
        return !configStates.name || !configStates.url || !configStates.cycle;
      case "googleFeedFile":
        return !configStates.name || !configStates.file;
      case "googleFeedDirectXml":
        return !configStates.name || !configStates.xml;
      default:
        break;
    }
  };

  const isFeedUrlSelected =
    selected === "googleFeedUrl" || selected === "metaFeedUrl";

  return (
    <section
      className={`${cm.contentContainer} ${isActive ? cm.active : ""}
       ${selected === "api" ? cm.apiMethod : ""}`}
      id="configurations"
    >
      <div className={cm.content}>
        <Trans>
          <h2 className={cm.title}>{t("components.datasource.configTitle")}</h2>
        </Trans>
        <p className={cm.text}>{t("components.datasource.configText")}</p>
      </div>
      {isActive && (
        <div className={cm.form}>
          <RetailFormInput
            isFocused={configStates.name !== ""}
            label={t("components.datasource.name")}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
            className="floating"
            help={t("components.datasource.nameHelp")}
          >
            <Input
              onChange={handleChange}
              className="floating"
              name="name"
              value={configStates.name}
            />
          </RetailFormInput>

          {selected === "googleFeedFile" && (
            <Upload.Dragger {...props}>
              <div className={cm.uploadContainer}>
                {configStates.file ? (
                  <Progress
                    type="circle"
                    trailColor="var(--secondary-3)"
                    width={70}
                    strokeColor={"var(--primary-4)"}
                    format={() => (
                      <RetailText weight="bold" className={cm.percent}>
                        100%
                      </RetailText>
                    )}
                    percent={100}
                  />
                ) : (
                  <UploadFilled />
                )}
                <RetailText
                  size="xs"
                  weight="medium"
                  className={cm.uploadTitle}
                >
                  {t(
                    `components.datasource.${
                      configStates.file ? "uploaded" : "upload"
                    }`
                  )}
                </RetailText>
                {!configStates.file && (
                  <RetailText
                    size="xxxs"
                    weight="medium"
                    className={cm.uploadText}
                  >
                    {t("components.datasource.uploadText")}
                  </RetailText>
                )}
              </div>
            </Upload.Dragger>
          )}
          {isFeedUrlSelected && (
            <>
              <RetailFormInput
                isFocused={configStates.url !== ""}
                label={t("components.datasource.url")}
                name="url"
                rules={[
                  {
                    required: true,
                    pattern: isValidURL,
                  },
                ]}
                className="floating"
                help={t("components.datasource.urlHelp")}
              >
                <Input
                  onChange={handleChange}
                  className="floating"
                  name="url"
                  value={configStates.url}
                />
              </RetailFormInput>
              <Form.Item
                label={""}
                name="cycle"
                rules={[{ required: true }]}
                help={t("components.datasource.cycleHelp")}
                className={cm.selectItem}
              >
                <RetailSelect
                  onChange={handleSelect}
                  value={configStates.cycle}
                  className={cm.select}
                  placeholder={t("components.datasource.cycle")}
                  options={options}
                />
              </Form.Item>
            </>
          )}

          {selected === "googleFeedDirectXml" && (
            <RetailFormInput
              isFocused={configStates.xml !== ""}
              label={t("components.datasource.xml")}
              name="xml"
              rules={[
                {
                  required: true,
                  message: t("components.datasource.xmlHelp"),
                },
              ]}
              className={`floating ${
                configStates.xml !== "" ? cm.directXmlInput : ""
              }`}
            >
              <Input.TextArea
                className={`floating ${cm.textarea} ${
                  configStates.xml !== "" ? cm.textareaFilled : ""
                }`}
                onChange={handleChange}
                name="xml"
                value={configStates.url}
              />
            </RetailFormInput>
          )}
          {selected !== "api" && (
            <RetailMainButton
              hasBackground={true}
              onClick={form.submit}
              className={cm.btn}
              loading={configStates.loading}
              disabled={switchDisabled()}
            >
              <TransferFilled />
              {t("components.datasource.fetch")}
            </RetailMainButton>
          )}
        </div>
      )}
    </section>
  );
};

export default RetailConfigurationsRow;
