import { useTranslation } from "react-i18next";
import RetailText from "../../Typography/RetailText";
import { ReactComponent as ChatOutlined } from "../../../assets/icons/chatOutlined.svg";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import cm from "./style.module.scss";

export interface RetailOnboardingContainerProps {
  text: string;
  sideImg?: string;
  step: "first" | "second" | "third" | "fourth";
  children?: React.ReactNode;
}

const RetailOnboardingContainer = ({
  text,
  sideImg,
  step,
  children,
}: RetailOnboardingContainerProps) => {
  const { t } = useTranslation();

  return (
    <div className={cm.wrapper}>
      <div className={cm.topInfo}>
        <Logo />
        <a
          href="https://gowit.com/book-a-demo"
          target="_blank"
          rel="noreferrer"
          className={`flex ${cm.helpBtn}`}
        >
          <ChatOutlined />
          {t("pages.auth.onboarding.help")}
        </a>
      </div>
      <RetailText
        weight="medium"
        className={`${cm.text} ${step === "fourth" ? cm.fourthStepText : ""}`}
      >
        {text}
      </RetailText>
      {children ? (
        children
      ) : (
        <img
          src={sideImg}
          alt="sideImg"
          className={`${cm.img} ${cm[`${step}StepImg`]}`}
        />
      )}
    </div>
  );
};

export default RetailOnboardingContainer;
