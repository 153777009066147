import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import cc from "classcat";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SearchOutlined } from "../../../assets/icons/searchOutlined.svg";
import RetailTransactionCard from "../../../components/Card/RetailTransactionCard";
import RetailDateColumn from "../../../components/Column/RetailDateColumn";
import RetailMoneyColumn from "../../../components/Column/RetailMoneyColumn";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import CampaignDetailsModal from "../../../components/Modal/CampaignDetailsModal";
import RetailInvoiceModal from "../../../components/Modal/RetailInvoiceModal";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import useAdvertiserBalance from "../../../hooks/useAdvertiserBalance";
import useSettings from "../../../hooks/useSettings";
import { numFormatter } from "../../../utils/helpers";
import cm from "./style.module.scss";

const InvoicesPage = () => {
  const { t } = useTranslation();

  const { data, isLoading, error } = useAdvertiserBalance();

  const { data: settings } = useSettings("ADVERTISER");

  const [visible, setVisible] = useState(false);

  const [detailsVisible, setDetailsVisible] = useState(false);

  const [details, setDetails] = useState<any>({ spend: null, budget: null });

  const [activeKey, setActiveKey] = useState("SPENDING");

  const openDetails = (records: any) => {
    setDetailsVisible(true);
    setDetails({
      spend: records.spend_details,
      budget: records.budget_details,
    });
  };

  const closeDetails = () => setDetailsVisible(false);

  const amountColInner = (value: number, transaction_type: string) => {
    switch (transaction_type) {
      case "SPEND":
      case "REFUND":
        return (
          <span className={`${cm.amount} ${cm.red}`}>
            - <RetailMoneyColumn value={value} />
          </span>
        );
      default:
        return (
          <span className={`${cm.amount} ${cm.green}`}>
            + <RetailMoneyColumn value={value} />
          </span>
        );
    }
  };

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "transaction_date":
      case "expire_date":
        return <RetailDateColumn value={value} />;
      case "transaction_type":
        return t(`pages.admin.transactions.${value?.toLowerCase()}`);
      case "description":
        return value
          ? t(`pages.admin.transactions.${`${value?.toLowerCase()}_desc`}`)
          : "-";
      case "status":
        return value
          ? t(`pages.admin.transactions.${value?.toLowerCase()}`)
          : "-";
      case "amount":
        return value ? amountColInner(value, records.transaction_type) : "-";
      case "balance":
        return <RetailMoneyColumn value={value} />;
      case "spend_details":
        return (
          value && (
            <SearchOutlined
              className={cm.icon}
              onClick={() => openDetails(records)}
            />
          )
        );
      default:
        return value ? value : "-";
    }
  };

  const tableConfig = {
    url: "transactions",
    isRelation: false,
    /* filters: reportFilters(t), */
    /*  tags,
    activeKey, */
    renderColumns,
  };

  const open = () => setVisible(true);

  const dateChecker = (date: string) => {
    if (date === "0001-01-01") return "-";
    else return moment(date).format("DD/MM/YYYY");
  };

  return (
    <RetailPageContainer>
      <div className={cm.cardContainer}>
        <RetailTransactionCard
          title={t("pages.acc.invoice.balance")}
          data={!isLoading && !error ? numFormatter(data?.data?.balance) : ""}
        />
        <RetailTransactionCard
          title={t("pages.acc.invoice.coupon")}
          data={!isLoading && !error ? numFormatter(data?.data?.coupons) : ""}
        />
        <RetailTransactionCard
          title={t("pages.acc.invoice.lastPayment")}
          data={
            !isLoading && !error
              ? {
                  amount: data?.data.amount,
                  date: dateChecker(data?.data?.created_at),
                }
              : null
          }
        />
      </div>
      <Row className="bordered-container full-width">
        <RetailTable
          tableConfig={tableConfig}
          rowSelection={undefined}
          placeholder={t("pages.acc.invoice.searchPlaceholder")}
          button={
            settings?.data?.is_smo
              ? undefined
              : { title: t("pages.acc.invoice.modal.button"), onClick: open }
          }
        />
      </Row>
      <RetailInvoiceModal
        type="INVOICE"
        subtitle={t("pages.acc.invoice.modal.subtitle")}
        visible={visible}
        setVisible={setVisible}
      />

      <CampaignDetailsModal
        type="TRANSACTION_DETAILS"
        subtitle={t("pages.admin.transactions.detailsSubtext")}
        visible={detailsVisible}
        onCancel={closeDetails}
      >
        <Tabs
          className={cc([
            "campaign-details-tab",
            activeKey === "targeting" ? "two-step-tabs" : "",
          ])}
          activeKey={activeKey}
          onChange={(value) => setActiveKey(value)}
        >
          <Tabs.TabPane
            key="SPENDING"
            tab={t("pages.admin.transactions.spendTab")}
            className={cm.tab}
          >
            {details.spend &&
              details.spend.map((item: any, index: number) => (
                <article
                  key={index}
                  className={cc(["flex", cm.spaceBetween, cm.line])}
                >
                  <RetailText weight="bold" size="xs" className={cm.item}>
                    {item.campaign}
                  </RetailText>
                  <RetailText
                    weight="medium"
                    size="xs"
                    family="poppins"
                    className={cm.item}
                  >
                    -<RetailMoneyColumn value={item.amount} />
                  </RetailText>
                </article>
              ))}
          </Tabs.TabPane>
          <Tabs.TabPane
            key="BUDGET"
            tab={t("pages.admin.transactions.budgetTab")}
            className={cm.tab}
          >
            {details.spend &&
              details.budget.map((item: any, index: number) => (
                <article
                  key={index}
                  className={cc(["flex", cm.spaceBetween, cm.line])}
                >
                  <RetailText weight="bold" size="xs" className={cm.item}>
                    {t(
                      `pages.admin.transactions.${item.resource.toLowerCase()}`
                    )}
                  </RetailText>
                  <RetailText
                    weight="medium"
                    size="xs"
                    family="poppins"
                    className={cm.item}
                  >
                    -<RetailMoneyColumn value={item.amount} />
                  </RetailText>
                </article>
              ))}
          </Tabs.TabPane>
        </Tabs>
      </CampaignDetailsModal>
    </RetailPageContainer>
  );
};

export default InvoicesPage;
