const jp = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/hedefleme-nasil-yapilir-hedefleme-turleri-nelerdir",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_targeting_text:
      "キャンペーンのターゲティング戦略を設定できます。",
    campaign_form_budget_text:
      "キャンペーンの予算タイプを選択し、予算を設定します。支出が予算に達すると、キャンペーンは自動的に停止します。",
    campaign_form_creative_text:
      "キャンペーンで使用するクリエイティブをアップロードできます。",
    invoice_modal_help_text:
      "［支払い方法を選択］ボタンをクリックして続行すると、支払われた金額が返金されないことに同意したものとみなされます。",
    invoice_general_error_modal_title: "残高を追加できませんでした。",
    invoice_general_error_modal_text: "エラーが発生しました。",
    invoice_unknown_error_modal_text: "予期しないエラーが発生しました。",
    invoice_product_id_error_modal_text: "製品IDのフィールドが必須です。",
    invoice_signature_error_modal_text: "署名を検証できませんでした。",
    invoice_transaction_error_modal_text: "取引IDはすでに取得されています。",
    invoice_store_error_modal_text:
      "この店舗は、指定された会社に属していません。",
    invoice_balance_error_modal_text: "店舗の残高が不足しています。",
    invoice_error_modal_title: "残高を追加できませんでした。",
    invoice_error_modal_text: "エラーが発生しました。",
  },
  marketplaceDependentTable: {
    CPC: "CPC",
    CPM: "CPM",
    RoAS: "ROAS",
  },
  marketplaceDependentSettings: {
    api_link: "https://docs.gowit.com/reference/getting-started-1",
    auto_impression_link: "https://docs.gowit.com/reference/sendevent",
    auction_link:
      "https://docs.gowit.com/docs/what-type-of-auctions-are-offered",
    product_link: "https://docs.gowit.com/docs/filters#what-is-a-product-rate",
    stock_link: "https://docs.gowit.com/docs/filters#what-is-a-product-stock",
    targeting_link:
      "https://docs.gowit.com/docs/filters#what-is-a-demographic-targeting-feature",
    bidding_link: "https://docs.gowit.com/docs/bidding-types",
  },
  sider: {
    user: {
      campaign: "キャンペーン",
      report: "レポート",
      campaigns: "キャンペーン",
      reports: "レポート",
      invoices: "請求書",
      academy: "アカデミー",
    },
    admin: {
      campaign: "キャンペーン",
      advertisers: "広告主",
      agencies: "代理店",
      invoices: "請求書",
      finance: "財務",
      transactions: "取引",
      coupons: "クーポン",
      access: "アクセス",
      users: "ユーザー",
      invited: "招待済み",
      synchronization: "同期履歴",
      activity: "活動履歴",
      report: "レポート",
      settings: "設定",
      general: "一般情報",
      integration: "統合情報",
      advertiser: "広告主の設定",
      auction: "オークションの設定",
      single: "キャンペーンの設定",
      campaigns: "キャンペーン",
      reports: "レポート",
      admin_report: "レポート",
      ad: "広告",
      placement: "広告配信面",
      creatives: "クリエイティブ",
      review: "広告レビュー",
      restrictions: "制限設定",
      console: "開発者コンソール",
    },
  },
  components: {
    adminBar: {
      rn: "現在表示しているのは、",
      visible: "広告主の画面です。",
      back: "管理者ビューに戻る",
    },
    advertiserSelect: {
      placeholder: "広告主を選択",
    },
    articles: {
      date: "キャンペーンの開始日と終了日は？",
      budget: "予算とは？予算を選ぶ際に考慮すべきことは？",
      bid: "入札と入札の種類は？",
      targeting:
        "ターゲティングの種類と正しいオーディエンスのターゲティング方法は？",
      frequency: "レポートの配信タイプとは？",
      type: "カスタムレポートの作成方法は？",
      product: "製品を選ぶ際に考慮すべきことは？",
      store: "店舗を選ぶ際に考慮すべきことは？",
    },
    breadcrumb: {
      finance: "取引",
      settings: "一般情報",
      access: "ユーザー",
    },
    bulkUpdate: {
      list: "選択したものをリスト",
      archive: "選択したものをアーカイブ",
      pause: "選択したものを一時停止",
      activate: "選択したものをアクティブ化",
      selected: "{{value}} 単位を選択済み",
      bid: "選択した入札額を編集",
      bidLabel: "入札額",
      bidHelp: "選択した行の入札額が更新されます。",
      row: "{{value}} 行",
      count: "選択：",
      updateAllBids: "入札額を編集",
      updateAllBidsText:
        "テーブルに表示されているすべての行の入札額を編集できます。",
      updatingAllBids: "すべての行の入札額を編集しています。",
      updateAllBidsHelp:
        "テーブルに表示されているすべての行の入札額が更新されます。",
    },
    datasource: {
      entity: "あなたのエンティティは何ですか？",
      method: "インポート方法は何ですか？",
      configurations: "設定を行ってください",
      mapping: "フィールドマッピング",
      emptyEntity: "あなたの<strong>エンティティ</strong>は何ですか？",
      emptyEntityText:
        "データを追加するエンティティの種類を選択してください。この選択によりデータの構造が定義され、インポートに必要なフィールドが決まります。エンティティには、商品や広告主などが含まれます。選択後、このエンティティに特有のデータフィールドを設定できます。",
      filledEntity: "<strong>商品</strong>",
      filledEntityText:
        "商品エンティティは、個々の商品に関連するデータを表します。商品ID、名前、説明、価格、フィルター、在庫情報などのフィールドを含みます。このエンティティは、各商品の詳細情報を取得し管理するために設計されています。",
      selectEntity: "エンティティを選択",
      selectEntityDescription:
        "データを追加するエンティティの種類を選択してください。この選択によりデータの構造が定義され、インポートに必要なフィールドが決まります。エンティティには、商品や広告主などが含まれます。選択後、このエンティティに特有のデータフィールドを設定できます。",
      product: "商品",
      productText:
        "商品エンティティは、個々の商品に関するデータを表します。商品ID、名前、説明、価格、フィルター、在庫情報などのフィールドが含まれます。このエンティティは、各商品の詳細な情報を取得し、管理するために設計されています。",
      seeProductFields: "商品データスキーマを見る",
      how: "使用方法を見る",
      advertiser: "広告主",
      advertiserText:
        "広告主エンティティは、広告主に関連するデータを表します。広告主ID、名前、その他の関連情報が含まれます。正しい統合を確保するために、必須フィールドには 'ID' と '名前' が含まれます。このエンティティは、広告主に関する重要な情報を整理および管理するのに役立ちます。",
      seeAdvertiserFields: "広告主データスキーマを見る",
      soon: "近日公開",
      productTitle: "商品データスキーマ",
      advertiserTitle: "広告主データスキーマ",
      required: "必須",
      showFields: "追加フィールドを表示",
      emptyMethod: "あなたの<strong>インポート方法</strong>は何ですか？",
      emptyMethodText:
        "選択したソースからデータをインポートする方法を選択してください。選択した方法に応じて、システムはデータを正しく処理するためにインポートプロセスを自動的に適応させます。各方法にはそれぞれの利点があるため、ニーズに最も適したものを選択してください。",
      filledMethod: "インポート方法：<strong>{{value}}</strong>",
      googleFeedUrl: "Google フィード URL",
      googleFeedFile: "Google フィードファイルアップロード (XML)",
      googleFeedDirectXml: "直接 XML アップロード（リクエストボディ）",
      filledMethodText:
        "Feed URLを提供するか、サポートされている形式でファイルをアップロードして、{{value}}からデータをインポートするためにこのオプションを使用します。",
      selectMethod: "インポート方法を選択",
      googleFeedText:
        "Google Feedは、Feed URLを提供するか、サポートされている形式でファイルをアップロードして、Google Feedからデータを直接インポートするために使用されます。この機能は、商品フィード統合時に広告主の作成を自動的に管理することで、プロセスを簡素化します。",
      feedUrl: "Feed URL",
      feedUrlText: "Googleフィードデータが直接ソースから取得される特定のURL。",
      fileUpload: "ファイルアップロード (XML)",
      fileUploadText:
        "Googleフィードファイルは、XML形式を使用して手動でインポートできます。",
      requestBody: "直接XMLアップロード (リクエスト本文)",
      requestBodyText:
        "リクエストボディ内のXMLを介してGoogleフィードデータをインポートできる直接統合方法で、従来のファイルアップロードを回避します。（近日公開予定の機能）",
      configTitle: "<strong>設定</strong>を構成",
      configText:
        "データソースの基本設定を定義します。これらの設定を正しく構成することで、データインポートプロセスが円滑に進み、データがシステム内で最新の状態に保たれます。データソースを保存した後は、これらの設定を変更することはできません。",
      name: "データソース名",
      nameHelp:
        "このデータソース構成に一意の名前を入力してください。後でソースを識別するのに役立ちます。",
      url: "Feed URL",
      urlHelp:
        "フィードを取得できる完全なURLを提供してください。システムはこのリンクから定期的にデータを取得します。",
      cycle: "サイクルを選択",
      cycleHelp:
        "システムが指定されたFeed URLから更新を取得する頻度を指定します。",
      fetch: "取得＆マッピング",
      mappingTitle: "フィールド<strong>マッピング</strong>",
      mappingText:
        "このセクションでは、データソースのフィールドをシステム内の対応するフィールドにマッピングします。システムフィールドには特定の要件があり、必須フィールドは正しくマッピングされる必要があり、インポートが成功するようにする必要があります。データソースに一致するデータがない必須フィールドについては、デフォルト値を設定することを選択できます。これにより、インポートプロセス中に重要な情報が欠落しないようにすることができます。フィールドのマッピングが完了したら、すべての必須フィールドが正しくマッチングされていることを確認してから、マッピングを検証する必要があります。",
      validate: "マッピングを検証",
      system: "システムフィールド",
      imported: "インポートされたフィールド",
      preview: "プレビュー",
      hideFields: "追加フィールドを非表示",
      feedUrlLink: "Google製品データ仕様",
      upload: "XML ファイルをアップロード",
      uploaded: "ファイルがアップロードされました！",
      uploadText: "またはここにドラッグ＆ドロップ",
      metaFeedText:
        "Meta Feedは、フィードURLやファイルアップロードを含むさまざまな形式でデータのインポートをサポートします。追加の対応フォーマットおよびフィールド設定は近日中に導入される予定です。",
      metaFeedUrlText:
        "Meta Feed統合のためにデータを直接取得する専用のURLソース。",
      metaFeedUrl: "Meta Feed URL",
      xml: "直接 XML コード",
      xmlHelp:
        "データをソースからインポートするために、XMLコードを直接入力してください。",
      api: "API統合",
      apiText:
        "API統合は、指定されたAPIエンドポイントへの接続を提供し、データの同期を可能にし、システムへの情報の継続的な流れを確保します。",
      apiButtonText:
        "指定されたAPIエンドポイントに接続してデータを取得するための設定を提供します。この統合により、当社のAPIへのリクエストを通じてデータの同期が可能になり、変更が発生すると最新の情報が直接システムに流れ込みます。",
      productSchema: {
        id: "商品のID",
        name: "商品の名前",
        advertiser_id: "広告主のID",
        image_url: "商品の画像URL",
        description: "商品の説明",
        price: "商品の価格（複数の場所では-1を返します）",
        filters: "商品に適用されるフィルター",
        buybox: "商品にbuyboxがあるかどうかを示します",
        sub_class_names: "商品のサブクラス名",
        brand: "商品のブランド",
        retailer_taxonomy: "商品の小売業者分類",
        google_taxonomy: "商品のGoogle分類",
        product_type: "商品の種類",
        global_identifier: "商品のグローバル識別子",
        global_identifier_type: "グローバル識別子の種類（GTINまたはASIN）",
        rating: "商品の評価",
        stock: "商品の在庫（複数の場所では-1を返します）",
        sku: "商品のSKU",
        barcode: "商品のバーコード",
        price_range: "商品の価格帯",
        status: "商品の状態",
        retailer_taxonomy_id_structure: "小売業者分類ID構造",
        process_id: "プロセスID",
      },
      advertiserSchema: {
        id: "広告主のID",
        name: "広告主の名前",
        time_zone: "広告主のタイムゾーン",
        email: "広告主のメールアドレス",
        language: "広告主の言語",
        status: "広告主のステータス",
        process_id: "広告主のプロセスID",
      },
    },
    download: {
      campaigns: "キャンペーン",
      advertisers: "広告主",
      agencies: "代理店",
      transactions: "取引",
      coupons: "クーポン",
      users: "ユーザー",
      sync_logs: "同期ログ",
      reports: "レポート",
      query: "パフォーマンスレポート",
      PRODUCTS: "製品",
      KEYWORDS: "キーワード",
      NEGATIVE_KEYWORDS: "ネガティブキーワード",
      AGE_RANGES: "年齢",
      CITIES: "都市",
      PLATFORMS: "プラットフォーム",
      GENDERS: "性別",
      PLACEMENTS: "広告配信面",
      CUSTOMERS: "顧客",
      CATEGORIES: "カテゴリー",
      NEGATIVE_CATEGORIES: "ネガティブカテゴリー",
      CREATIVES: "クリエイティブ",
    },
    info: {
      firstTipCreate:
        "推奨設定を選ぶことで、簡単に効果的なキャンペーンを作成できます。推奨設定は広告のパフォーマンスを最適化します。",
      secondTipCreate:
        "キャンペーンのパフォーマンスを向上させるために、十分な在庫があり、高評価で、Buy Boxを獲得した製品を選択することができます。",
      thirdTipCreate:
        "広告予算を増やすことで、製品の視認性を向上させ、クリック数と売上を伸ばすことができます。",
      forthTipCreate:
        "キャンペーンで高額入札を設定することで、製品がより目立つ位置に表示されるようになります。",
      survey: "簡単なアンケートにご回答いただき、改善にご協力ください。",
    },
    invite: {
      selected: "{{value}} 単位を選択済み",
      invite: "選択した人を招待",
      reinvite: "選択した人を再招待",
    },
    modal: {
      campaign: {
        create: "新しいキャンペーンを作成",
        success: "キャンペーンが作成されました",
        successText:
          "［OK］ボタンをクリックすると、キャンペーンの詳細ページに進むことができます。",
      },
      campaignDetails: {
        products: "製品の選択",
        keywords: "キーワードを追加",
        update: "キャンペーンを更新",
        negative_keywords: "ネガティブキーワードを追加",
        products_btn: "製品を追加",
        keywords_btn: "キーワードを追加",
        negative_keywords_btn: "ネガティブキーワードを追加",
        update_btn: "更新",
        create_agency: "新しい代理店を追加",
        update_agency: "代理店を更新",
        create_agency_btn: "追加",
        update_agency_btn: "更新",
        create_user: "新規ユーザーを追加",
        create_user_marketplace: "管理ユーザーを追加",
        create_user_marketplace_btn: "追加",
        create_user_advertiser: "広告主ユーザーを追加",
        create_user_advertiser_btn: "追加",
        select_advertiser: "広告主を選択",
        select_advertiser_btn: "続行",
        update_advertiser: "広告主を更新",
        update_advertiser_btn: "更新",
        invoice: "残高を追加",
        invoice_btn: "支払い方法を選択",
        coupons: "クーポンを定義",
        coupons_btn: "クーポンを定義",
        transactions: "残高を追加",
        transactions_btn: "残高を追加",
        categories: "新しいカテゴリーを追加",
        categories_btn: "カテゴリーを追加",
        negative_categories: "ネガティブカテゴリーを追加",
        negative_categories_btn: "カテゴリーを追加",
        transaction_details: "取引の詳細",
        settings: "変更内容を保存",
        settings_btn: "保存",
        creatives: "クリエイティブを追加",
        creatives_btn: "クリエイティブを追加",
        approve_balance: "残高を追加",
        approve_balance_btn: "取引を確認",
        approve_coupon: "クーポンを追加",
        approve_coupon_btn: "クーポンを確認",
        payment_options: "残高を追加",
        payment_options_btn: "読み込み",
        add_user: "新しいユーザーを招待",
        add_user_btn: "招待状を送信",
        targeting_settings: "広告主の選択",
        targeting_settings_btn: "選択",
        update_transaction: "請求書番号を編集",
        update_transaction_btn: "保存",
        select_user: "ユーザータイプを選択",
        select_user_btn: "続行",
        select_language: "言語を変更",
        select_language_btn: "変更",
        campaign_form: "キャンペーン概要",
        campaign_form_btn: "キャンペーンを作成",
        update_bid: "入札額を編集",
        update_bid_btn: "編集",
        update_dayparting: "時間帯指定",
        update_dayparting_btn: "更新",
        reject_creative: "クリエイティブを拒否",
        reject_creative_btn: "確認",
      },
      selectCampaign: {
        title: "キャンペーンタイプを選択",
        text: "キャンペーンタイプを選択してください。",
        soon: "近日",
        sponsored_product_title: "スポンサー製品",
        sponsored_product_text:
          "関連キーワードを検索したり、類似製品を閲覧したりする顧客にプロモーションします。",
        sponsored_brand_title: "スポンサーブランド",
        sponsored_brand_text:
          "魅力的なクリエイティブを使用して、顧客にブランドや製品を見つけてもらいましょう。",
        sponsored_display_title: "スポンサーディスプレイ",
        sponsored_display_text:
          "オンサイトとオフサイトで関連するオーディエンスにリーチして、ビジネスを成長させます。",
        sponsored_store_title: "スポンサーストア",
        sponsored_store_text:
          "店舗のリーチを拡大し、買い物客があなたの店舗を見つけやすくしましょう。ターゲットを絞ったショッピング体験を提供します。",
        sponsored_video_title: "スポンサービデオ",
        sponsored_video_text:
          "ビデオ広告でターゲットオーディエンスにリーチし、魅力的な要素を使って売上を増やします。",
      },
      success: {
        advertisers_success_title: "招待状が送信されました",
        advertisers_success_text:
          "入力されたメールアドレスに招待状が送信されました。ユーザーは、招待リンクをクリックして、アカウントにアクセスするためのパスワードを作成する必要があります。",
        reports_success_title: "レポートが作成されました",
        reports_success_text:
          "［OK］ボタンをクリックすると、レポートの詳細ページに進むことができます。",
        invited_success_title: "ユーザーが招待されました",
        invited_success_text:
          "ユーザーがリストに表示されるためには、招待メールをクリックしてパスワードを作成する必要があります。",
        agencies_success_title: "代理店が作成されました",
        agencies_success_text: "nan",
        multiple_advertisers_success_title: "招待状が送信されました",
        multiple_advertisers_success_text: "選択された広告主が招待されました。",
        invoice_success_title: "残高を追加しました。",
        invoice_success_text:
          "残高が追加されました。新しいキャンペーンを作成できます。",
        create_advertiser_success_title: "広告主を追加しました。",
        create_advertiser_success_text:
          "広告主を有効にするには、招待する必要があります。",
        payment_success_title: "残高の追加リクエストを受け付けました。",
        payment_success_text:
          "送金またはEFT支払いが承認されると、アカウントに転送されます。承認プロセスには2〜3営業日かかります。",
        craftgate_success_title: "残高の追加リクエストを受け付けました。",
        craftgate_success_text: "残高が迅速にアカウントに転送されます。",
        file_success_title: "ファイルが正常にアップロードされました！",
        file_success_text:
          "ファイルが正常にアップロードされ、処理されました。次のステップに進むことができます。",
        xml_success_title: "XML が正常に処理されました！",
        xml_success_text:
          "XML データが正常に処理されました。次のステップに進むことができます。",
      },
      error: {
        craftgate_title: "残高が追加されませんでした。",
        craftgate_text: "クレジットカードでの残高追加に失敗しました。",
        file_title: "XML ファイルアップロードエラー",
        file_text:
          "XML ファイルの処理中に問題が発生しました。ファイル形式が正しく、必要な構造に合っていることを確認してください。問題が解決しない場合は、サポートに連絡して追加の支援を受けてください。",
        xml_title: "XML 処理エラー",
        xml_text:
          "XML データの処理中に問題が発生しました。XML の構造と内容が正しいことを確認してください。問題が解決しない場合は、サポートに連絡して追加の支援を受けてください。",
      },
      warning: {
        campaign_warning_title: "このページを離れてもよろしいですか？",
        campaign_warning_text: "変更は保存されません！",
      },
    },
    navbar: {
      search: "入力して検索...",
      help: "ヘルプ＆サポート",
      site: "販売者パネルに戻る",
    },
    navbarPopover: {
      account: "アカウントを変更",
      language: "言語を変更",
      logout: "ログアウト",
    },
    notification: {
      formTitle: "フォームが完了していません。",
      formText: "空欄と必須フィールドに入力してください。",
      paused: "{{value}} を停止しました。",
      active: "{{value}} を有効化しました。",
      campaigns: "キャンペーン",
      reports: "レポート",
      products: "製品",
      keywords: "キーワード",
      negative_keywords: "ネガティブキーワード",
      categories: "カテゴリー",
      negative_categories: "ネガティブカテゴリー",
      placements: "広告配信面",
      users: "ユーザー",
      advertisers: "広告主",
      agencies: "代理店",
      ad_placements: "広告配信面",
      creatives: "クリエイティブ",
      account_users: "ユーザー",
      statusError: "エラーが発生しました。",
      activeReport: "レポートをアーカイブしました。",
      archivedReport: "レポートのアーカイブを解除しました。",
      activeCampaign: "キャンペーンをアーカイブしました。",
      pausedCampaign: "キャンペーンをアーカイブしました。",
      archivedCampaign: "キャンペーンのアーカイブを解除しました。",
      activeAgency: "代理店をアーカイブしました。",
      archivedAgency: "代理店のアーカイブを解除しました。",
      invitedAdvertiser: "広告主をアーカイブしました。",
      activeAdvertiser: "広告主のアーカイブを解除しました。",
      archivedAdvertiser: "広告主をアーカイブしました。",
      archived_reports_bulk: "レポートをアーカイブしました。",
      archived_campaigns_bulk: "キャンペーンをアーカイブしました。",
      archived_users_bulk: "ユーザーをアーカイブしました。",
      archived_advertisers_bulk: "広告主をアーカイブしました。",
      archived_agencies_bulk: "代理店をアーカイブしました。",
      archived_account_users_bulk: "ユーザーをアーカイブしました。",
      keyword: "少なくとも1つのキーワードを追加してください。",
      category: "少なくとも1つのカテゴリーを追加してください。",
      invite: "{{value}} 件の招待状が送信されました。",
      full_invite: "すべての招待状が送信されました。",
      rejected: "クリエイティブの拒否が完了しました。",
      accepted: "クリエイティブの検証が完了しました。",
      uninvitedAdvertiser: "広告主を有効にしました。",
      settings_success: "設定が保存されました。",
      previous_invite_sent_too_recently:
        "{{value}} 件の招待状が最近送信されました。しばらくたってから再度お試しください。",
      user_email_is_already_registered:
        "{{value}} 件のメールアドレスはすでに登録されています。",
      copiedCampaign: "キャンペーンをコピーしました。",
      copyError: "キャンペーンをコピー中にエラーが発生しました。",
      editableFieldError:
        "最小値を超える値を入力してください。超えない場合は、自動的に調整されます。",
      active_account_user: "ユーザーをアーカイブしました。",
      archived_account_user: "ユーザーのアーカイブを解除しました。",
      bid: "{{value}} 行の入札額が更新されました。",
      newBid: "新しい「入札額」：{{value}}。",
    },
    suggestedTag: {
      text: "おすすめ",
    },
    campaignCard: {
      graph: "グラフに表示",
      hide: "非表示",
      message: "過去30日間",
    },
    campaignForm: {
      firstStep: {
        keywordStepTitle: "キーワード情報",
        categoryStepTitle: "カテゴリー情報",
        targetingStepTitle: "オーディエンス情報",
        campaignInfoTitle: "キャンペーン情報",
        campaignInfoText:
          "キャンペーンテーブルに表示される名前を入力してください。この名前は顧客には表示されません。宣伝したい製品に関連する名前を選ぶことをお勧めします。",
        campaignLabel: "キャンペーン名",
        campaignSub: "キャンペーン名を入力します。",
        dateInfoTitle: "キャンペーン日付",
        dateInfoText: "キャンペーンの期間を設定します。",
        startDateLabel: "開始日",
        startDateSub: "キャンペーンの開始日を選択します。",
        stopped: "継続",
        stoppedText: "キャンペーンは今日開始し、停止するまで続行されます。",
        stoppedSub: "開始「日」：{{value}}",
        defined: "開始日 - 終了日",
        definedText: "キャンペーンが実行される期間を選択できます。",
        budget: "予算",
        totalBudget: "総予算の制限",
        totalBudgetLabel: "総予算",
        dailyBudgetLabel: "1日の予算",
        dailyBudgetExtra:
          "キャンペーンの1日の費用は、指定した最大金額になります。",
        dailyBudgetFilter: "1日の予算",
        dailyBudgetText: "1日の予算を設定できます。",
        totalBudgetExtra:
          "キャンペーンでは、毎日指定した最大金額が消費されます。",
        flexibleBudgetLabel: "1日の予算",
        totalBudgetFilter: "総予算",
        totalBudgetText: "総予算を設定できます。",
        bid: "入札額",
        bidText:
          "キャンペーンの入札タイプを設定します。広告費用は、選択した入札タイプに基づいてクリックごとに発生します。",
        dynamicBid: "自動入札額",
        dynamicBidText: "クリックあたりの費用は、自動的に決定されます。",
        targetAcosTitle: "ターゲットACoS",
        targetAcosText:
          "広告からの売上のどれくらいを広告費として使用するかを選択します。",
        default: "デフォルト",
        advanced: "詳細設定",
        fixedBid: "固定入札額",
        fixedBidText:
          "キーワードおよびカテゴリーごとに固定のクリック単価を設定します。キャンペーンは、顧客のターゲティング設定に基づいて表示されます。",
        productTitle: "製品の選択",
        productText: "広告を出したい製品を選択します。",
        acosTooltip:
          "ACoSは、広告費用対売上を意味します。これは、売上に対してどれだけの金額を広告に費やすかを示す比率です。例えば、ACoSが10%のキャンペーンでは、10円の売上に対して1円を広告に使うことになります。",
        autoTargeting: "自動ターゲティング",
        autoTargetingText:
          "キャンペーンに最適なキーワードまたはカテゴリーがターゲットになります。",
        manualTargeting: "手動ターゲティング",
        manualTargetingText:
          "キャンペーンを表示するキーワードまたはカテゴリーを設定できます。",
        suggestedBidText: "推奨「入札額」：",
        acosError: "ACoSは、0.0001〜100の範囲である必要があります。",
        creativeTitle: "クリエイティブの情報",
        videoText:
          "キャンペーンで使用するクリエイティブをアップロードできます。",
        urlTitle: "リダイレクトURL",
        urlText:
          "広告をクリックしたユーザーが誘導されるページです。ユーザーをマーケットプレイス内のページにリダイレクトする必要があります。",
        draggerTitle:
          "クリエイティブをアップロードするには、.PNG、.JPG、.GIFファイルをアップロードするか、",
        draggerText: "ここにドラッグ＆ドロップ",
        creativeLabel: "クリエイティブ名",
        size: "サイズ：",
        listWarningSize:
          "クリエイティブのファイルサイズの上限は、5120 KBです。",
        listWarningType: ".jpg、.png .gifファイルに対応しています。",
        listWarningRecommended:
          "広告配信面の次元と受け入れ可能なクリエイティブサイズを確認できます。",
        modalTitle: "クリエイティブの次元",
        modalText:
          "リストにあるすべてのサイズのクリエイティブをアップロードできます。推奨サイズでクリエイティブをアップロードすると、広告のパフォーマンスが向上します。",
        recommended: "推奨サイズ",
        accepted: "対応可能サイズ",
        creativeError: "クリエイティブ名を入力してください。",
        urlError: "URLを入力してください。",
        budgetError: "予算は{{value}}以上である必要があります。",
        budgetErrorMessage: "予算を入力してください。",
        invalid: "無効なサイズ",
        advancedOptions: "詳細オプション",
        placements: "広告配信面",
        placementsText: "広告を表示する領域を指定します。",
        in_search: "検索結果",
        in_search_text: "広告は関連する検索結果に表示されます。",
        in_product_detail_page: "製品詳細ページ",
        in_product_detail_page_text:
          "広告は関連する製品詳細のページに表示されます。",
        in_category: "カテゴリー",
        in_category_text: "広告は関連するカテゴリーのページに表示されます。",
        in_home_page: "ホームページ",
        in_home_page_text: "広告はホームページに表示されます。",
        in_collection: "コレクション",
        in_collection_text:
          "広告は関連するコレクションのページに表示されます。",
        storeTitle: "店舗選択",
        storeText: "広告を出したい店舗を選択します。",
        info: "一般情報",
        advertiser: "広告主「名」：",
        budgetType: "予算「タイプ」：",
        bidType: "入札「タイプ」：",
        standart: "自動入札額",
        fixed: "固定入札額",
        max_acos: "ターゲットACoS",
        fixed_info: "入札額：",
        max_acos_info: "ACoS：",
        targeting: "ターゲティング",
        custom: "カスタム",
        durationText:
          "キャンペーンが毎日の日次予算ターゲットに達すると、<strong>{{value}}日間</strong>アクティブな状態を維持します。",
        dayParting: "時間帯指定",
        dayPartingText: "キャンペーンを有効にする日と時間を設定します。",
        allTimes: "すべての時間帯",
        allTimesText: "キャンペーンは一日中、全時間帯で有効です。",
        scheduled: "日付範囲を選択",
        scheduledText: "キャンペーンは、指定した日と時間帯に有効になります。",
        dayPartingTableText:
          "以下の<strong>ボックス</strong>にチェックを入れて、希望する日と時間帯を選択してください。1日すべての時間帯を希望する場合は、<strong>［終日］</strong>のオプションを選択できます。",
        allDay: "終日",
        clear: "選択をクリア",
        languageTitle: "クリエイティブな言語を選択",
        languageSearch: "言語を検索...",
        autoTargetingProductWarning:
          "自動ターゲティングを有効にするには、<strong>関連商品</strong>ステップから少なくとも<strong>“1”</strong>つの商品を選択する必要があります。",
        connectedProductsTitle: "関連商品",
        connectedProductsText:
          "上記のクリエイティブに関連する、あなたのために特別にキュレーションされた商品。",
        showProducts: "関連商品を表示",
        showProductsText:
          "このオプションを有効にして、クリエイティブに関連する商品を追加します。関連する商品をクリエイティブに関連付けることで、広告の関連性を高めることができます。",
        promoteProduct: "商品を宣伝",
        promoteProductText:
          "この機能は、キャンペーンに追加した商品が目立つ位置に表示されるようにします。",
        promoteProductWarning:
          "一度選択すると、このオプションは変更できませんのでご注意ください。",
        closeProducts: "関連商品を閉じる",
        closeProductsText:
          "クリエイティブに関連する商品の使用を無効にすることは推奨されません。広告の関連性が低下する可能性があります。",
        spendingTitle: "支出分配",
        spendingText: "広告予算の時間配分と強度を定義します。",
        spendingSub: "支出分配を決定します。",
        asap: "できるだけ早く",
        front_loaded: "フロントローディング",
        evenly: "均等に",
        frontPercentage: "割合",
        multiRegion: "地域選択",
        multiRegionText:
          "キャンペーンは複数の地域または特定の地域で実行できます。",
        allRegions: "すべての地域",
        allRegionsText: "キャンペーンはすべての地域で公開されます。",
        selectedRegions: "選択された地域",
        selectedRegionsText: "キャンペーンは選択された地域のみで公開されます。",
        regionPlaceholder: "地域を選択してください",
        evenlyText:
          "キャンペーンは着実に進行し、すべての期間に均等に焦点が当てられています。",
        asapText:
          "予算は迅速に消費され、キャンペーン初期に早急な結果を得ます。",
        front_loadedText:
          "開始時に多くの労力が集中し、その後徐々に減少して存在感を維持します。",
        errorStates: {
          name: "キャンペーン名を入力してください。",
          keywordErr: "キーワードは一意である必要があります。",
          advertiserErr: "広告主はアクティブではありません。",
          placementsError: "少なくとも1つの広告配信面を選択してください。",
          productLimitErr: "250製品まで追加可能です。",
          sameKeyword:
            "ターゲット キーワードと除外キーワードに同じキーワードを含めることはできません。",
          duplicateKeyword:
            "キーワードはすでにキャンペーンに追加されています。",
          duplicateNegativeKeyword:
            "ネガティブキーワードはすでにキャンペーンに追加されています。",
          generalErr: "エラーが発生しました。",
          keywordLimitErr:
            "キャンペーンには最大100個のキーワードを追加できます。",
          categoryLimitErr:
            "キャンペーンには最大100個のカテゴリを追加できます。",
          frontPercentage:
            "フロントローディングされた割合は0から100の間でなければなりません。",
        },
      },
      secondStep: {
        wordTargeting: "キーワードターゲティング",
        wordTargetingText: "広告を表示するキーワードを指定します。",
        categorySub: "広告が表示されるカテゴリーを指定します。",
        autoTargeting: "自動ターゲティング",
        autoTargetingText:
          "広告は最も関連性の高いキーワードとカテゴリーに表示されます。キーワードの停止は、キャンペーン詳細ページから行えます。",
        manualTargeting: "手動ターゲティング",
        manualTargetingText: "広告のキーワードを設定できます。",
        defaultBid: "デフォルトの入札額",
        keywordTitle: "ターゲットキーワード",
        keywordText: "広告を表示するキーワードを追加します。",
        negativeTitle: "ネガティブキーワード",
        negativeText: "広告を表示したくないキーワードを追加します。",
        placementTitle: "ターゲティングタイプ",
        placementText:
          "キャンペーンが表示される特定のキーワードやカテゴリーをターゲットにできます。",
        search: "キーワードターゲティング",
        searchText: "キャンペーンが表示されるキーワードをターゲットにします。",
        categories: "カテゴリーターゲティング",
        categoriesText:
          "キャンペーンが表示されるカテゴリーをターゲットにします。",
        categoryTargeting: "ターゲティング",
        categoryTargetingTitle: "広告を表示するカテゴリーを指定します。",
        categoryTargetingText: "広告を表示するカテゴリーを追加します。",
        category: "ターゲットカテゴリー",
        negativeCategoryTitle: "ネガティブカテゴリー",
        negativeCategoryText: "広告を表示したくないカテゴリーを追加します。",
        autoCategoryText: "広告は最も適切なカテゴリーに表示されます。",
        manualCategoryText: "広告のターゲットカテゴリーを設定できます。",
        placement: "ターゲティング",
        targetingText: "キャンペーンのターゲティング戦略を設定できます。",
      },
      thirdStep: {
        targeting: "オーディエンス選択",
        targetingText: "広告を表示するオーディエンスを指定します。",
        autoTargeting: "自動ターゲティング",
        autoTargetingText:
          "広告は最も関連性の高いキーワードとカテゴリーに表示されます。",
        manualTargeting: "手動ターゲティング",
        manualTargetingText: "製品を表示させたいオーディエンスを決定できます。",
        platform: "プラットフォームターゲティング",
        platformText: "広告を表示するプラットフォームユーザーを指定します。",
        tablet: "タブレット",
        mobile: "モバイル",
        app: "モバイルアプリケーション",
        desktop: "デスクトップ",
        age: "年齢層ターゲティング",
        ageText: "広告を表示する年齢層を指定します。",
        city: "都市ターゲティング",
        cityText: "広告を表示する都市を指定します。",
        allCities: "すべての都市",
        selectedCities: "選択された都市",
        cityPlaceholder: "都市を選択",
        customer: "顧客ターゲティング",
        customerText: "購買履歴に基づいて広告を表示する顧客を指定します。",
        presentCustomers: "既存の顧客",
        presentCustomersText:
          "広告に出ている製品を注文したことのある顧客をターゲットにします。",
        newCustomers: "新規顧客",
        newCustomersText:
          "広告に出ている製品を一度も注文したことのない顧客をターゲットにします。",
        gender: "性別ターゲティング",
        genderText: "広告を表示する性別を指定します。",
        female: "女性",
        male: "男性",
      },
    },
    columnPopover: {
      title: "列を編集",
    },
    empty: {
      product_title: "製品を選択してください。",
      store_title: "店舗を選択してください。",
      dimensions_title: "次元が追加されていません。",
      product_text:
        "左側の製品リストの［追加］ボタンをクリックして、広告を表示させたい製品を追加できます。",
      store_text: "広告を表示させたい店舗を追加できます。",
      dimensions_text:
        "次元を追加して、キャンペーン結果の詳細レベルをカスタマイズできます。",
      null_title: "データなし",
      null_text: "テーブルにデータがありません。",
      categories_title: "対象にするカテゴリーを追加してください。",
      categories_text:
        "広告を表示したいカテゴリーを指定するには、カテゴリーを入力するか、推奨されるカテゴリー表の［追加］ボタンをクリックします。",
      keywords_title: "対象にするキーワードを追加してください。",
      keywords_text:
        "広告を表示したいキーワードを指定するには、キーワードを入力するか、推奨されるキーワード表の［追加］ボタンをクリックします。",
      negative_keywords_title: "ネガティブキーワードが追加されていません。",
      negative_keywords_text:
        "広告を表示させたくないネガティブキーワードを入力して指定できます。",
      negative_categories_title: "ネガティブカテゴリーが追加されていません。",
      negative_categories_text:
        "広告を表示させたくないネガティブカテゴリーを入力して指定できます。",
      campaigns_table_title: "キャンペーンが作成されていません",
      campaigns_table_text:
        "［新しいキャンペーンを追加］ボタンをクリックして、最初のキャンペーンを作成できます。",
      reports_table_title: "レポートが作成されていません",
      reports_table_text:
        "［新しいレポートを追加］ボタンをクリックして、最初のレポートを作成できます。",
      invoices_table_title: "残高が追加されていません",
      invoices_table_text:
        "広告費用（残高）を追加するには、アカウントマネージャーにお問合せください。",
      advertisers_table_title: "アカウントにリンクされた広告主がいません",
      advertisers_table_text: "アカウントマネージャーにお問合せください。",
      agencies_table_title: "代理店が作成されていません",
      agencies_table_text:
        "［新しい代理店を追加］ボタンをクリックして代理店を作成できます。",
      transactions_table_title: "残高が追加されていません",
      transactions_table_text:
        "［残高を追加］ボタンをクリックして、広告主のリストに広告費用（残高）を追加できます。",
      coupons_table_title: "クーポンが作成されていません",
      coupons_table_text:
        "［クーポンを定義］ボタンをクリックして、広告主にクーポンを定義できます。",
      account_users_table_title: "ユーザーが作成されていません",
      account_users_table_text:
        "［新しいユーザーを追加］ボタンをクリックして、ユーザーを作成できます。",
      archived_table_reports_title: "アーカイブされたレポートはありません",
      archived_table_campaigns_title:
        "アーカイブされたキャンペーンはありません",
      archived_table_agencies_title: "アーカイブされた代理店はありません",
      archived_table_account_users_title: "アーカイブされたユーザーはいません",
      archived_table_advertisers_title: "アーカイブされた広告主はいません",
      invited_table_users_title: "招待されたユーザーはありません",
      searched_table_title: "検索条件に一致する記録は見つかりませんでした。",
      searched_table_text: "nan",
      searched_title: "検索条件に一致する記録は見つかりませんでした。",
      searched_text: "nan",
      products_table_title: "製品が追加されていません。",
      products_table_text:
        "［製品を追加］ボタンをクリックして、広告を出したい製品を追加できます。",
      keywords_table_title: "キーワードが追加されていません。",
      keywords_table_text:
        "広告を表示したいキーワードを、単語を入力して指定できます。",
      categories_table_title: "カテゴリーが追加されていません。",
      categories_table_text:
        "広告を表示させたいカテゴリーを入力して指定できます。",
      negative_keywords_table_title:
        "ネガティブキーワードが追加されていません。",
      negative_keywords_table_text:
        "広告を表示させたくないネガティブキーワードを入力して指定できます。",
      negative_categories_table_title:
        "ネガティブカテゴリーが追加されていません。",
      negative_categories_table_text:
        "広告を表示させたくないネガティブカテゴリーを入力して指定できます。",
      sync_logs_table_title: "同期履歴データがありません",
      sync_logs_table_text: "同期履歴データが見つかりませんでした。",
      ad_placements_table_title: "広告配信面が追加されていません。",
      ad_placements_table_text:
        "［広告配信面を追加］ボタンをクリックして、広告配信面を追加できます。",
      creatives_table_title: "クリエイティブが追加されていません。",
      creatives_table_text:
        "追加されたクリエイティブは、後でここで確認できます。",
      creatives_relations_table_title: "クリエイティブが作成されていません。",
      creatives_relations_table_text:
        "キャンペーンフォームから新しいクリエイティブを作成できます。",
      creativeForm_title: "クリエイティブが追加されていません。",
      creativeForm_text: "推奨クリエイティブ「サイズ」：",
      invites_table_title: "ユーザーが招待されていません。",
      invites_table_text:
        "［新しいユーザーを追加］ボタンをクリックして、ユーザーを招待できます。",
      review_title: "レビューするクリエイティブはありません。",
      review_text:
        "すべてのクリエイティブが承認されたか、新しいクリエイティブがありません。承認待ちのクリエイティブは、この画面に表示されます。",
      accepted_table_creatives_title: "承認されたクリエイティブはありません。",
      accepted_table_creatives_text:
        "すべてのクリエイティブが拒否されたか、新しいクリエイティブがありません。",
      rejected_table_creatives_title: "拒否されたクリエイティブはありません。",
      rejected_table_creatives_text:
        "すべてのクリエイティブが承認されたか、新しいクリエイティブがありません。",
      pending_table_creatives_title: "追加されたクリエイティブはありません。",
      pending_table_creatives_text:
        "すべてのクリエイティブがレビューされたか、新しいクリエイティブがありません。",
      blacklist_table_title: "キーワード制限が見つかりませんでした。",
      blacklist_table_text:
        "［新しいキーワードを追加］ボタンをクリックして、制限を追加できます。",
      cities_table_title: "都市が追加されていません。",
      cities_table_text: "nan",
      age_ranges_table_title: "年齢範囲が追加されていません。",
      age_ranges_table_text: "nan",
      genders_table_title: "性別が追加されていません。",
      genders_table_text: "nan",
      platforms_table_title: "プラットフォームが追加されていません。",
      platforms_table_text: "nan",
      customers_table_title: "顧客が追加されていません。",
      customers_table_text: "nan",
      report_loading_title: "レポートを生成しています",
      report_loading_text:
        "レポートを作成中です。複雑さやサイズによっては、完了までに時間がかかることがあります。",
      data_sources_table_title: "データソースが見つかりません",
      data_sources_table_text:
        "現在、データソースはありません。データソースを追加して、インポートの詳細と履歴を確認してください。",
      logs_title: "ログが見つかりません",
      logs_text:
        "現在、このカテゴリには記録されたログはありません。関連するイベントが発生すると、ここにログが表示されます。",
    },
    endDatePicker: {
      label: "終了日",
      campaigns: "キャンペーンの終了日を選択します。",
      reports: "レポートの終了日を選択します。",
      button: "終了日なし",
    },
    reportForm: {
      title: "レポート情報",
      nameTitle: "レポート名",
      nameText: "レポートテーブルに表示される名前を入力します。",
      nameSub: "レポート名を入力します。",
      reportTypeTitle: "配信",
      reportTypeText:
        "レポートの配信方法を選択します。生成されたレポートは、指定した方法で配信されます。",
      static: "固定",
      staticText: "レポートは1回実行され、レポートに表示されます。",
      scheduled: "スケジュール設定",
      scheduledText: "レポートの頻度と受信者を選択します。",
      frequency: "レポートの頻度を選択します。",
      daily: "毎日",
      weekly: "毎週",
      monthly: "毎月",
      sendDate: "最初のレポートを受け取る日付を選択します。",
      firstDay: "月の初日",
      lastDay: "月の最終日",
      exportType: "レポートを受け取る形式を選択します。",
      mail: "レポートが送信されるメールアドレスを入力します。",
      dateTitle: "レポート日付",
      dateText: "レポートの開始日と終了日を選択します。",
      startDateSub: "レポートの開始日を選択します。",
      reportKindTitle: "レポートタイプ",
      reportKindText:
        "定義済みのレポートを使うと、簡単にパフォーマンスを測定できます。カスタムレポートを使うと、次元や指標、フィルターを追加して、自分に合わせたレポートを作成できます。",
      predefined: "定義済みのレポート",
      predefinedPlaceholder: "定義済みのレポートタイプを選択",
      special: "カスタムレポート",
      breaks: "次元",
      breaksText: "レポートに含める次元を選択します。",
      breaksPlaceholder: "次元を検索",
      maxWarning: "*レポートに最大3つの次元を追加できます。",
      clear: "クリア",
      metrics: "指標",
      metricsText: "レポートに含める指標を選択します。",
      filters: "フィルター：",
      filtersText: "次元と指標に適用するフィルターを選択します。",
      product: "製品のパフォーマンス",
      product_category: "カテゴリーのパフォーマンス",
      product_brand: "ブランドのパフォーマンス",
      keyword: "キーワードのパフォーマンス",
      campaign: "キャンペーンのパフォーマンス",
      advertiser: "広告主のパフォーマンス",
      agency: "代理店のパフォーマンス",
      errorStates: {
        date: "日付を選択してください。",
        mail: "メールアドレスを入力してください。",
        type: "レポートタイプを選択してください。",
        metric: "少なくとも1つの指標を選択してください。",
      },
      dimensions: {
        campaignName: "キャンペーン名",
        campaignBidType: "入札タイプ",
        campaignBudgetType: "予算タイプ",
        product: "製品",
        categoryName: "カテゴリーのページ",
        keywordText: "キーワード",
        productBrand: "製品ブランド",
        productName: "製品",
        day: "日",
        week: "週",
        month: "月",
        year: "年",
        hour: "時間",
        advertiser: "広告主ID",
        advertiserName: "広告主",
        agency: "代理店ID",
        agencyName: "代理店",
        customerDeviceType: "顧客デバイスタイプ",
        saleProductName: "間接販売製品",
        placementId: "プレースメントID",
        customerType: "顧客タイプ",
        productRetailerTaxonomy: "製品カテゴリー",
        adPlacementCustomKey: "広告配信面ID",
        adPlacementName: "広告配信面名",
        placementText: "プレースメントテキスト",
        in_search: "検索結果",
        in_category: "カテゴリーのページ",
        in_product_detail_page: "製品詳細ページ",
        in_home_page: "ホームページ",
        campaignAdType: "キャンペーンタイプ",
        campaignBudget: "予算",
        campaignFixedBid: "キャンペーン固定入札額",
        keywordBid: "キーワード入札額",
        categoryBid: "カテゴリー入札額",
        advertiserResourceID: "販売者ID",
        productSKU: "製品ID",
        customerDeviceEnvironmentType: "顧客デバイス環境タイプ",
        campaignDailyBudget: "1日の予算",
        campaignTotalBudget: "総予算",
      },
      metric: {
        sumImpression: "インプレッション数",
        sumViewableImpression: "表示可能なインプレッション",
        sumClick: "クリック数",
        CTR: "CTR",
        CVR: "CVR",
        sumSale: "販売",
        ACoS: "ACoS",
        sumDirectSale: "直接販売",
        sumIndirectSale: "間接販売",
        sumDirectSaleAmount: "直接販売額",
        sumIndirectSaleAmount: "間接販売額",
        sumSaleAmount: "販売額",
        sumSpend: "支出",
        sale: "販売",
        sumVideoStart: "動画開始数",
        sumVideoComplete: "動画完了数",
        VCR: "動画完了率",
        viewabilityRate: "視認率",
        fillRate: "充填率",
      },
    },
    selectFilter: {
      title: "フィルターオプション",
      placeholder: "フィルターを選択",
      budgetOpt: "残りの予算",
      budgetAdvertiser: "広告主名",
      placeholderCondition: "条件を選択",
      bigger: "より大きい",
      smaller: "より小さい",
      equal: "等しい",
      notEqual: "等しくない",
      any: "含む",
      add: "フィルターを追加",
      placeholderValue: "値を選択",
      enter: "値を入力",
      date: "日付を選択",
    },
    searchBar: {
      keywords: "キーワードを追加...",
      negative_keywords: "キーワードを追加...",
      categories: "カテゴリーを追加...",
      negative_categories: "カテゴリーを追加...",
    },
    settingsHeader: {
      general: "一般情報",
      generalText: "マーケットプレイスに関する一般情報",
      integration: "統合情報",
      integrationText: "データ転送を実行するために必要な統合情報",
      advertiser: "広告主の設定",
      advertiserText: "広告主に関連する設定",
      auction: "オークションの設定",
      auctionText: "オークションに関連する設定",
      campaign: "キャンペーン設定",
      campaignText: "キャンペーン設定に関連する設定",
      bid: "入札額制限",
      bidText: "プラットフォーム全体の入札ルールに関する設定",
      conversion: "コンバージョン",
      conversionText: "プラットフォーム上のコンバージョン目標に関連する設定",
      category: "カテゴリー制限",
      categoryText: "プラットフォーム上に表示されるカテゴリーの制限",
      keyword: "キーワードの制限",
      keywordText: "プラットフォーム上で検索されるキーワードの制限",
    },
    stepsNavbar: {
      report_create: "レポートを作成",
      campaign_create: "キャンペーンを作成",
      report_edit: "レポートを更新",
      step: "次のステップは {{value}}",
      advertiser: "広告主：",
    },
    suggestionCard: {
      addAll: "すべて追加",
    },
    table: {
      keywords: {
        keyword: "キーワード",
        matchType: "一致タイプ",
        suggested: "推奨入札額",
        dynamic: "自動",
        volume: "検索ボリューム",
        competition: "競争",
        low: "1000未満",
        medium: "1K-5K",
        high: "5K-10K",
        highest: "10K以上",
        search: "キーワードを追加...",
        suggestTitle: "推奨キーワード",
        suggestText: "製品の推奨キーワード。",
        negativePlaceholder: "ネガティブキーワードを追加...",
        exact: "キーワードに正確に一致する検索語句。",
        phrase: "キーワードのコンポーネントを同じ順序で含む検索語句。",
        broad: "キーワードのコンポーネントを任意の順序で含む検索語句。",
        warning: "最小値は、{{value}}である必要があります。",
      },
      products: {
        productSearch: "製品、SKU、バーコードを検索します。",
        storeSearch: "店舗を検索",
        loading: "読み込み中",
        title: "製品を選択してください。",
        text: "左側の製品リストの［追加］ボタンをクリックして、広告を表示させたい製品を追加できます。",
        productSelected: "選択された製品",
        storeSelected: "選択された店舗",
        remove: "すべて削除",
        price: "価格",
        stock: "在庫",
        rating: "評価",
        drawerTitle: "一括製品選択",
        sku: "SKUまたはバーコードリスト",
        skuText:
          "追加したい製品のSKUまたはバーコードを、以下のフィールドに1行ずつ入力します。",
        categories: "すべてのカテゴリー",
        bulk: "一括選択",
        all: "すべて表示",
        selected: "選択された製品を表示",
        total: "合計<strong>{{value}} 製品</strong>があります。",
        variable: "変数",
      },
      categories: {
        suggestTitle: "推奨カテゴリー",
        suggestText: "製品の推奨カテゴリー",
      },
      result: {
        campaignName: "キャンペーン名",
        campaign: "キャンペーンID",
        campaignBidType: "入札タイプ",
        campaignBudgetType: "予算タイプ",
        advertiserName: "広告主名",
        agencyName: "代理店名",
        product: "製品",
        productName: "製品",
        categoryText: "カテゴリー",
        keyword: "キーワードID",
        keywordText: "キーワードテキスト",
        keywordMatchType: "一致タイプ",
        productBrand: "製品ブランド",
        day: "日",
        week: "週",
        month: "月",
        year: "年",
        hour: "時間",
        sumImpression: "インプレッション数",
        sumViewableImpression: "表示可能なインプレッション",
        sumClick: "クリック数",
        CTR: "CTR",
        CVR: "CVR",
        sumSale: "販売",
        ACoS: "ACoS",
        sumDirectSale: "直接販売",
        sumIndirectSale: "間接販売",
        sumDirectSaleAmount: "直接販売額",
        sumIndirectSaleAmount: "間接販売額",
        sumSaleAmount: "販売額",
        sumSpend: "支出",
        campaignStartDate: "開始日",
        campaignEndDate: "終了日",
        campaignStatus: "ステータス",
        campaignBudget: "予算",
        advertiser: "広告主",
        agency: "代理店",
        weekOf: "{{value}}の週",
        customerDeviceType: "顧客デバイスタイプ",
        saleProductName: "間接販売製品",
        placementId: "プレースメントID",
        customerType: "顧客タイプ",
        categoryName: "カテゴリーのページ",
        productRetailerTaxonomy: "製品カテゴリー",
        adPlacementCustomKey: "広告配信面ID",
        adPlacementName: "広告配信面名",
        placementText: "プレースメントテキスト",
        in_search: "検索結果",
        in_category: "カテゴリーのページ",
        in_product_detail_page: "製品詳細ページ",
        in_home_page: "ホームページ",
        in_collection: "コレクション",
        campaignAdType: "キャンペーンタイプ",
        campaignFixedBid: "キャンペーン固定入札額",
        keywordBid: "キーワード入札額",
        categoryBid: "カテゴリー入札額",
        advertiserResourceID: "販売者ID",
        productSKU: "製品ID",
        customerDeviceEnvironmentType: "顧客デバイス環境タイプ",
        campaignDailyBudget: "1日の予算",
        campaignTotalBudget: "総予算",
        sumVideoStart: "動画開始数",
        sumVideoComplete: "動画完了数",
        VCR: "動画完了率",
        viewabilityRate: "視認率",
        fillRate: "充填率",
      },
    },
    states: {
      campaigns_not_started: "キャンペーンはまだ開始されていません。",
      campaigns_date_passed: "キャンペーンが、スケジュール終了日を過ぎました。",
      campaigns_status_paused: "キャンペーンを一時停止しました。",
      campaigns_out_of_daily_budget:
        "キャンペーンの1日の予算がすべて使われました。",
      campaigns_out_of_total_budget: "キャンペーンの総予算上限に達しました。",
      campaigns_products_stopped: "製品がアクティブではありません。",
      campaigns_keywords_stopped: "キーワードがアクティブではありません。",
      campaigns_categories_stopped: "カテゴリーがアクティブではありません。",
      campaigns_status_archived: "キャンペーンをアーカイブしました。",
      campaigns_creatives_stopped: "アクティブなクリエイティブがありません。",
      campaigns_insufficient_balance: "残高が不足しています。",
      campaigns_low_bid: "入札額が最低入札額を下回っています。",
      campaigns_regions_stopped: "地域がアクティブではありません。",
      products_status_paused: "製品を一時停止しました。",
      products_low_stock: "在庫の制約により、ステータスが停止されました。",
      products_low_rating: "評価の制約により、ステータスが停止されました。",
      products_buybox_loser:
        "Buy Boxの制約により、ステータスが停止されました。",
      products_product_paused: "製品がアクティブではありません。",
      products_product_deleted: "製品がアクティブではありません。",
      keywords_status_paused: "キーワードのステータスを一時停止しました。",
      keywords_low_bid: "入札額が最低入札額を下回っています。",
      categories_status_paused: "カテゴリーターゲティングが停止されました。",
      categories_low_bid: "入札額が最低入札額を下回っています。",
      creatives_paused: "クリエイティブのステータスを一時停止しました。",
      creatives_pending: "クリエイティブがレビュー待ちです。",
      creatives_rejected: "クリエイティブが拒否されました。",
      creatives_note: "拒否の理由:",
    },
    payment: {
      amount: "「チャージ」金額：",
      title: "支払い方法",
      subtitle: "広告残高はVATを含む価格で請求されます。",
      wire_transfer: "銀行振込 / EFT",
      beymen_milestone_payment: "マイルストーン支払い",
      koctas_milestone_payment: "マイルストーン支払い",
      bank: "銀行情報",
      helpUpper:
        "送金またはEFTでの支払いが承認された後、転送されます。承認プロセスは、",
      helpSecondary: "2〜3営業日かかります。",
      ref: "参照「番号」：",
      info: "取引の追跡のためには、取引の説明に参照番号を正しく入力することが非常に重要です。",
      tax: "VAT：",
      verifyAmount: "「口座」から引き出される額：",
      toters_milestone_payment: "ウォレットから転送",
      product_placeholder: "店舗を検索",
      craftgate: "クレジットカード",
    },
    tour: {
      prev: "戻る",
      next: "次へ",
      done: "ツアーを終了する",
      title: "広告プラットフォームへようこそ。",
      intro:
        "製品ツアーを表示するには、［開始］をクリックします。ツアーをスキップするには、［終了］ボタンをクリックします。",
      menu: "メニューバー",
      menuText: "左側のメニューバーを使用して、ページ間を移動できます。",
      campaigns: "キャンペーン",
      campaignsText:
        "［キャンペーン］ページから広告キャンペーンを管理できます。ここで、広告から得られるクリック数や販売数を確認できます。",
      reports: "レポート",
      reportsText:
        "広告のパフォーマンスに関するレポートを、さまざまな次元で作成できます。これらのレポートを使用すると、広告の表示回数、どの検索結果やカテゴリーで表示されたか、販売数などを確認できます。",
      reportsTextSub:
        "レポートを作成する際には、定義済みのレポートを選ぶか、自分で作成するカスタムレポートを選択できます。",
      invoices: "請求書",
      invoicesText:
        "このセクションでは、キャンペーンに使用するためにアップロードした残高、獲得したギフトクーポン、すべての広告費用を確認できます。",
      academy: "アカデミー",
      academyText:
        "ここでは、プラットフォームの使用に関する必要な情報を見つけることができます。",
      widgets: "統計",
      widgetsText:
        "作成したキャンペーンのパフォーマンスを段階的に確認できます。また、［グラフに表示］ボタンをクリックして日次ベースで広告パフォーマンスを詳細に確認することもできます。",
      table: "キャンペーンテーブル",
      tableText:
        "このセクションでは、作成したキャンペーンとそのパフォーマンスを確認できます。キャンペーンを一時停止することも可能です。キャンペーンの詳細を表示するには、キャンペーン名をクリックします。",
      button: "新しいキャンペーンを追加",
      buttonText:
        "［新しいキャンペーンを追加］ボタンをクリックして、最初の広告キャンペーンを作成します。",
      modal: "キャンペーンタイプ",
      modalText: "ここでは、選択可能な広告キャンペーンの種類を確認できます。",
      type: "スポンサー製品",
      typeText:
        "スポンサー製品のキャンペーンを作成すると、検索結果やカテゴリーページの上位に製品を表示させることができます。",
    },
  },
  pages: {
    acc: {
      campaigns: {
        button: "新しいキャンペーンを追加",
        placeholder: "キャンペーン内を検索",
        errorTitle: "広告主が選択されていません",
        errorText: "広告主を選択してください。",
        table: {
          standart: "自動",
          max_acos: "自動",
          fixed: "固定",
          daily: "毎日",
          flexible: "毎日",
          total: "合計",
          budgetLimit: "{{value}}に制限",
          stopped: "キャンペーンを停止しました。",
          product: "スポンサー製品",
          display: "スポンサーディスプレイ",
          store: "スポンサーストア",
          default: "デフォルトのビュー",
          checkUp: "チェックアップビュー",
        },
      },
      campaignDetails: {
        subtext: "キャンペーン情報を編集できます。",
        products: "製品",
        on: "アクティブ",
        keywords: "キーワード",
        negativeKeywords: "ネガティブキーワード",
        targeting: "オーディエンス",
        city: "都市",
        age: "年齢",
        gender: "性別",
        platform: "プラットフォーム",
        customers: "顧客",
        placements: "広告配信面",
        in_search: "検索結果",
        in_category: "カテゴリー",
        placementPlaceholder: "広告配信面内を検索",
        date: "開始-終了「日」：",
        bid: "入札額：",
        maxAcos: "% 最大ACoS",
        fixed: "固定",
        standart: "自動",
        daily: "毎日",
        total: "合計",
        flexible: "毎日",
        none: "なし",
        keywordsBtn: "キーワードを追加",
        keywordsPlaceholder: "キーワードを検索",
        keywordsSub: "広告を表示するキーワードを追加します。",
        negative_keywords_btn: "ネガティブキーワードを追加",
        negative_keywords_placeholder: "ネガティブキーワード内を検索",
        negative_keywords_modal_subtitle:
          "広告を表示したくないキーワードを追加します。",
        negative_categories_btn: "ネガティブカテゴリーを追加",
        negative_categories_placeholder: "ネガティブカテゴリー内を検索",
        negative_categories_modal_subtitle:
          "広告を表示したくないカテゴリーを追加します。",
        productsBtn: "製品を追加",
        productsPlaceholder: "製品を検索",
        productsSub: "広告を出したい製品を選択します。",
        cities_placeholder: "都市を検索",
        age_ranges_placeholder: "年齢範囲を検索",
        genders_placeholder: "性別を検索",
        platforms_placeholder: "プラットフォームを検索",
        customers_placeholder: "顧客タイプを検索",
        new: "新規顧客",
        present: "既存の顧客",
        keywordTitle: "キーワードターゲティング",
        keywordText:
          "キーワードは自動的に広告のターゲットに設定されます。ターゲットにしたくないキーワードは停止できます。",
        maxAcosLabel: "最大ACoS",
        fixedBidLabel: "固定入札額",
        productsError: "少なくとも1つの製品がアクティブである必要があります。",
        keywordsError:
          "少なくとも1つのキーワードがアクティブである必要があります。",
        categoriesError:
          "少なくとも1つのカテゴリーがアクティブである必要があります。",
        categories: "カテゴリー",
        categoriesBtn: "カテゴリーを追加",
        categoriesPlaceholder: "カテゴリー内を検索",
        categoriesSub: "ターゲットにするカテゴリーを追加できます。",
        negativeCategories: "ネガティブカテゴリー",
        buyboxError: "製品はBuy Boxを獲得していません。",
        ratingError: "製品の評価が最低評価を下回っています。",
        stockError: "製品の在庫が最低在庫を下回っています。",
        notActiveError: "製品がアクティブではありません。",
        creatives: "クリエイティブ",
        creativesBtn: "クリエイティブを追加",
        creativesPlaceholder: "クリエイティブ内を検索",
        creativesSub: "広告に表示するクリエイティブを追加します。",
        creativesLinkTitle: "リダイレクトURL",
        creativesLinkText:
          "広告をクリックしたユーザーは、{{value}} にリダイレクトされます。",
        winner: "勝者",
        loser: "敗者",
        creativesError:
          "少なくとも1つのクリエイティブがアクティブである必要があります。",
        pendingError: "保留中のクリエイティブはアクティブにできません。",
        rejectedError: "拒否されたクリエイティブはアクティブにできません。",
        in_product_detail_page: "製品詳細ページ",
        in_home_page: "ホームページ",
        in_collection: "コレクション",
        scheduled: "スケジュール済み",
        dayParting: "キャンペーンがアクティブになる曜日と時間を選択できます。",
        region: "地域:",
        regionLength: "{{value}} 地域",
        regionsDrawerTitle: "地域を更新",
        languages: "{{value}} 言語",
      },
      noMatch: {
        title: "ページが見つかりません。",
        btn: "ホームに戻る",
      },
      reports: {
        button: "新しいレポートを追加",
        placeholder: "レポート内を検索",
        name: "レポート名",
        table: {
          type: "レポートタイプ",
        },
        form: {
          title: "レポート1",
          goBack: "戻る",
          create: "レポートを作成",
        },
      },
      reportDetails: {
        result: "レポート結果",
        static: "固定",
        scheduled: "スケジュール設定",
        ready: "定義済みのレポート",
        startDate: "開始日",
        endDate: "終了日",
        frequency: "頻度",
        firstDate: "最初の投稿日",
        month: "月の初日",
        emails: "メール",
        export: "ファイルタイプ",
        predefined: "定義済みのレポートタイプ",
        total: "合計統計を取得",
      },
      invoice: {
        balance: "残高",
        coupon: "クーポン",
        lastPayment: "最後に追加された残高",
        searchPlaceholder: "請求書内を検索",
        duplicateCouponsError:
          "複数のクーポン定義が検出されました。しばらくお待ちください。",
        multipleTopUpsError:
          "複数の残高アップロードが検出されました。しばらくお待ちください。",
        limitError:
          "高額の残高を追加しようとしています。金額を確認してください。",
        minAmountError: "最低支払額は {{value}} である必要があります。",
        data: {
          typeBudget: "残高支払い",
          typeAd: "広告費用",
          typeBudgetExplanation: "広告残高を追加しました。",
          typeAdExplanation: "広告費用が使われました。",
        },
        table: {
          type: "取引タイプ",
        },
        modal: {
          button: "残高を追加",
          title: "残高を追加",
          subtitle: "広告残高に転送したい金額を入力します。",
          loadingAmount: "金額",
          differentAmount: "金額を入力します。",
          label: "金額",
        },
      },
      academy: {
        more: "詳細を見る",
        copy: "リンクをコピー",
        helpTitle: "質問に対する回答が見つかりませんか？",
        helpText:
          "ご不明な点がございましたら、サポートチームまでメールでお問い合わせください。",
        contact: "お問い合わせ",
        highlights: "ハイライト",
        read: "記事の残りを読む",
        description:
          "{{value}}の広告ガイドの役立つ記事をご覧ください。プラットフォームに関する知識が身につき、簡単に広告を掲載できるようになります。",
      },
    },
    admin: {
      advertisers: {
        updateSubtext: "広告主を代理店に割り当ててグループ化できます。",
        modalPlaceholder: "代理店を検索...",
        search: "広告主内を検索",
        select_advertiser_subtitle:
          "キャンペーンを作成する広告主を選択してください。",
        userSubtext: "広告主アカウントを使用するユーザーを招待できます。",
        help: "ユーザーのメールアドレスを追加",
        deleteSuccess: "代理店が削除されました。",
        create: "新しい広告主を追加",
        update: "広告主を更新",
        type: "広告主「タイプ」：",
        custom: "カスタム",
        customText:
          "プラットフォーム内で共有可能なベンダーの製品を使って作成された広告主のタイプです。",
        name: "広告主名",
        nameHelp: "表示される広告主の名前です。",
        agency: "代理店",
        agencyHelp: "広告主が所属する代理店です。",
        email: "メール",
        emailHelp:
          "招待状を送信するメールアドレスを入力します。販売者はプラットフォームにログインする際に、このメールアドレスを使用します。",
        product: "製品名",
        brand: "ブランド",
        retailer_taxonomy: "製品カテゴリー",
        advertiser_id: "広告主ID",
        filters: "フィルター：",
        filterWarning: "少なくとも1つのフィルターを追加してください。",
        typeTitle: "広告主のタイプ",
        typeSubtext:
          "以下のうち、上で選択した広告主のタイプをどれに変更しますか？",
        default: "デフォルト",
        defaultText:
          "プラットフォーム内で自分の名前で直接販売する販売者アカウントに使用されます。",
        shareable: "共有可能",
        shareableText:
          "プラットフォーム内で他者の代理として販売を行い、売上を主要な販売者に配分するための販売者アカウントに使用されます。",
        generalTitle: "一般情報",
        generalSubtext: "広告主に関する一般情報",
        successText:
          "選択した販売者のブランドに一致する、すべての製品が追加されました。",
        advertiser: "広告主：",
        drawerFilterWarning: "追加したフィルターは元に戻せません。",
        filterTitle: "フィルター",
        keyLabel: "フィルターを選択",
        opLabel: "条件を選択",
        valueLabel: "値を入力",
        categorySelectLabel: "カテゴリーを選択",
        filterKeyError: "フィルターを選択してください。",
        filterOpError: "条件を選択してください。",
        filterValueError: "値を入力してください。",
        filterCategoryError: "カテゴリーを選択してください。",
      },
      advertiserSettings: {
        title: "広告主の設定",
        subtext: "広告主に関連する設定",
        status: "デフォルトの広告主ステータス",
        statusText:
          "プラットフォームに追加された広告主のデフォルトステータスが無効になっている場合、広告主ページでステータスを有効にし、システムにログインできるようにする必要があります。",
        welcome: "ウェルカムクーポン",
        welcomeText:
          "広告主がプラットフォームに初めてアクセスした時に使用可能なウェルカムクーポンを定義できます。",
        warning:
          "「0」を入力すると、広告主にはウェルカムクーポンが定義されません。",
        label: "クーポン残高",
        language: "言語",
        languageText:
          "広告主のデフォルトのインターフェース言語です。広告主は自身のインターフェースを通じて言語選択を変更できます。",
        balance: "残高追加の認証",
        balanceText:
          "広告主がインターフェースを通じて進行中の支払いを広告残高に転送する権限です。",
        open: "開く",
        closed: "閉じた",
        error_title: "値を入力する必要があります。",
        error_text:
          "クーポンを定義しない場合は、「0」を入力して［保存］ボタンをクリックできます。",
        error_text_input: "値を入力する必要があります。",
      },
      agencies: {
        label: "代理店名",
        subtext: "代理店名を入力",
        subtitle: "代理店を追加して、広告主をグループ化できます",
        search: "代理店内を検索",
        edit: "代理店名を更新",
      },
      auctionSettings: {
        title: "オークションの設定",
        subtext: "オークションに関連する設定",
        type: "オークションタイプ",
        typeText:
          "広告主の入札額が評価されるオークションタイプを選択します。オークションタイプの詳細情報は、こちらをご覧ください。",
        warning: "変更はプラットフォーム上の広告費用に影響します。",
        firstOpt: "最初の価格",
        firstOptText: "落札した広告主は提出した入札額を支払います。",
        secondOpt: "第二価格",
        secondOptText:
          "落札した広告主は2番目に高い入札額より1セント高い金額を支払います。",
        link: "オークションタイプ",
      },
      campaignSettings: {
        title: "キャンペーン設定",
        subtext: "キャンペーン設定に関連する設定",
        conversionRange: "コンバージョン範囲",
        conversionRangeText:
          "ユーザーの購入活動が広告クリックに起因する期間を指定できます。コンバージョン範囲を7日に設定すると、ユーザーが広告をクリックしてから7日以内に製品を購入した場合、そのキャンペーンの残高が使用されます。",
        product: "製品評価",
        productText:
          "広告主がキャンペーンに追加する製品に対して最低在庫要件を設定し、この条件を満たさない製品の動作をカスタマイズすることができます。",
        minimum: "最低製品評価",
        minimumText:
          "キャンペーンに追加される製品の最低評価を入力してください。",
        behaviour: "動作を設定",
        behaviourText: "キャンペーンに追加される製品の最低評価を入力します。",
        placeholder: "動作を選択",
        stock: "製品在庫",
        stockText:
          "広告主がキャンペーンに追加する製品に対して最低在庫要件を設定し、この条件を満たさない製品の動作をカスタマイズすることができます。",
        minimumStock: "最低製品在庫",
        minimumStockText:
          "キャンペーンに追加される製品の最低在庫を入力します。",
        stockBehaviourText:
          "指定した最低在庫を下回ったときの動作を指定します。",
        alert: "警告のみ",
        block: "警告と広告ブロック",
        targeting: "人口統計ターゲティング",
        targetingText:
          "キャンペーン設定時に、人口統計ターゲティングの設定を有効にするかどうかを選択してください。有効にすると、キャンペーン設定に追加のステップが追加され、広告主がターゲティングの設定を行えるようになります。",
        all: "すべての広告主",
        none: "広告主なし",
        selected: "選択した広告主",
        format: "広告形式",
        sponsored: "スポンサー製品",
        brand: "スポンサーブランド",
        ad: "ビジュアル広告",
        rating_warn:
          "製品に対する評価が低すぎます。この製品をキャンペーンに追加すると、パフォーマンスが低下します。",
        rating_block:
          "製品に対する評価が低すぎます。この製品をキャンペーンに追加できません。",
        stock_warn:
          "在庫が少なすぎます。この製品をキャンペーンに追加すると、パフォーマンスが低下します。",
        stock_block:
          "在庫が少なすぎます。この製品をキャンペーンに追加できません。",
        buybox_warn:
          "この製品はBuy Boxを獲得していません。この製品をキャンペーンに追加すると、パフォーマンスが低下します。",
        buybox_block: "製品はBuy Boxを獲得していません。",
        buyBoxTitle: "Buy Boxの条件",
        buyBoxText:
          "広告主がキャンペーンに追加する製品に対して、Buy Boxの獲得条件を追加することができます。",
        buyBoxSubtext:
          "キャンペーンに追加される製品がBuy Boxの勝者でない場合の動作を指定します。",
        noWorning: "なし",
        warn: "変更は、すべてのアクティブなキャンペーンに影響します。",
        rangeError: "値を入力する必要があります。推奨値は7日間です。",
        rangeErrorInput: "1日から14日までの値のみを入力できます。",
        chooseBehaviour: "動作を選択する必要があります。",
        stockError: "最低在庫を入力する必要があります。",
        allStockError: "最低在庫を入力し、動作を選択する必要があります。",
        ratingError: "最低評価を入力する必要があります。",
        allRatingError: "最低評価を入力し、動作を選択する必要があります。",
        serverBlock: "警告と広告表示をブロック",
        advertisers: "選択した広告主",
        advertisersText:
          "人口統計ターゲティングは、選択した広告主のみが利用可能になります。",
        advertisersBtn: "広告主を追加",
        advertisersSub:
          "人口統計ターゲティングを使用可能な広告主を選択します。",
        advertisersPlaceholder: "広告主を選択",
        advertisersLength: "{{value}} 件の広告主が選択されました。",
        advertisersTitle: "広告主",
        general: "一般",
        bid: "入札額制限",
        conversion: "コンバージョン",
        conversionSubtext:
          "プラットフォームでのコンバージョン目標に関連する設定",
        biddingTypes: "スポンサー製品を利用可能な入札タイプ",
        biddingTypesText:
          "キャンペーンの作成ステップで、広告主が使用できる入札タイプを指定できます。",
        biddingTypesWarning:
          "少なくとも1つのオプションが開いている必要があります。",
        auto: "自動入札額",
        autoText:
          "入札額が自動的に決定される入札タイプです。費用は販売された製品の価格と設定したターゲットACoSを使用して計算されます。",
        acos: "ターゲットACoS",
        acosText:
          "入札額は、広告主が入力したACoSの率と販売された製品の価格に基づいて、自動的に計算されます。",
        fixed: "固定入札額",
        fixedText:
          "入札額は広告主によって決定されます。広告主はキーワードおよびカテゴリーごとにキャンペーン全体の入札額を設定できます。",
        bidWarning: "少なくとも1つの入札タイプが選択されている必要があります。",
        bidText: "プラットフォーム全体の入札ルールに関連する設定",
        bidLabelTitle: "全体の最小CPC",
        bidLabelText:
          "すべてのカテゴリーおよびキーワードに対して入力する必要がある、クリックあたりの最低入札額です。",
        bidLabel: "最小CPC",
        cpmTitle: "全体の最小CPM",
        cpmText:
          "すべてのカテゴリーおよびキーワードに対して入力する必要がある、インプレッションあたりの最低入札額です。",
        cpmLabel: "最小CPM",
        targetAcosTitle: "一般ターゲットACoS",
        targetAcosText:
          "オークションでデフォルトでターゲットとするACoSの最大割合を設定できます。",
        targetAcosLabel: "最大ACoS",
        categoryRelevanceTitle: "一般カテゴリー関連性",
        categoryRelevanceText:
          "広告インプレッションにおいて、スポンサー製品とオーガニックリスト製品の間で必要とされる関連性のレベルを指定します。",
        categoryRelevanceLabel: "カテゴリー関連性",
        bidTabWarning:
          "変更はプラットフォーム上の広告費用とインプレッション数に影響します。",
        inputErrorZero: "ゼロより大きい値を入力する必要があります。",
        inputErrorAcos: "0〜100の間の値を入力する必要があります。",
        inputErrorRelevance: "ゼロより大きい整数を入力する必要があります。",
        inputError: "値を入力する必要があります。",
        productLink: "製品レート制限",
        stockLink: "製品在庫制限",
        targetingLink: "人口統計ターゲティング機能",
        bidLink: "入札タイプ",
        minThreshold: "最低販売閾値",
        minThresholdText:
          "これは、製品をオークションに含めるための最低販売閾値です。",
        minThresholdLabel: "最低販売閾値",
        minThresholdError: "整数を入力してください。",
        formatTitle: "フォーマット別アトリビューション設定",
        formatText:
          "タッチポイントは、さまざまなキャンペーン形式での有効性を測定するために評価されます。形式に応じて、インプレッションを選択すると視認性が追跡され、クリックは直接のエンゲージメントを通じてコンバージョンの測定に焦点を当てます。",
        sponsoredProductTitle: "スポンサー製品",
        sponsoredProductText:
          "製品広告では、コンバージョンはユーザーの広告とのインタラクションに基づいて評価され、エンゲージメントまたは露出が目標達成にどれだけ効果的かに焦点が当てられます。",
        sponsoredDisplayTitle: "スポンサー表示",
        sponsoredDisplayText:
          "ディスプレイ広告では、単なる可視性がユーザーの行動に影響を与えるか、または成功に向けてより深いインタラクションが必要かを分析してコンバージョンを測定します。",
        sponsoredVideoTitle: "スポンサー動画",
        sponsoredVideoText:
          "動画広告では、コンバージョンの測定は、単にコンテンツを視聴することが目標達成にどれほど寄与するか、またはアクティブなエンゲージメントがどれだけ効果的かに焦点を当てます。",
        postView: "ポストビュー",
        postClick: "ポストクリック",
      },
      console: {
        add: "新しいデータソースを追加",
        advertiser: "広告主",
        product: "商品",
        google_feed_url_scheduler: "Google Feed URL",
        google_feed_file: "Google フィードファイルアップロード (XML)",
        google_feed_body: "Google フィード直接 XML",
        meta_feed_url_scheduler: "Meta フィード URL",
        minutes_15: "15分",
        minutes_30: "30分",
        hour_1: "1時間",
        hours_2: "2時間",
        hours_3: "3時間",
        hours_4: "4時間",
        hours_6: "6時間",
        hours_12: "12時間",
        hours_24: "24時間",
        history: "履歴を表示",
        logs: "ログを表示",
        drawer: "ログビュー",
        error: "エラー",
        warning: "警告",
        success: "成功",
        info: "情報",
        prev: "前の 50 ログ",
        next: "次の 50 ログ",
        api: "API",
      },
      coupons: {
        add: "クーポンを定義",
        title: "広告主情報",
        subtext:
          "広告主がキャンペーンの費用として使用するクーポンを定義できます。",
        label: "有効期限",
        warning:
          "広告主は入力した日付までにクーポンを引き換える必要があります。有効期限を入力しない場合、クーポンは広告主の残高に追加され（期限なし）、広告主はいつでもクーポンを使用できます。",
        gift_coupon: "ギフトクーポン",
        welcome_coupon: "ウェルカムクーポン",
        gift_coupon_desc: "ギフトクーポンが定義されました。",
        welcome_coupon_desc: "ウェルカムクーポンが定義されました。",
        approve: "クーポンの定義を続行しますか？",
        note: "クーポンの説明",
        noteHelp:
          "クーポンを定義する理由を含めます。例：ブラックフライデークーポン",
      },
      createDatasource: {
        title: "データソースを追加",
        save: "保存して実行",
        existsErr:
          "このURLのデータソースはすでに存在します。別のURLを使用してください。",
        feedErr:
          "このタイプのインテグレーションでは、フィードURLを空にすることはできません。有効なURLを入力してください。",
        cycleErr:
          "このタイプのインテグレーションでは、サイクル間隔を空にすることはできません。有効な間隔を指定してください。",
        permissionErr:
          "指定されたURLにアクセスできません。URLまたは権限を確認してください。",
        generalErr: "エラーが発生しました。",
        validateSuccess: "検証成功",
        validateSuccessText:
          "すべての必須フィールドが正しくマッピングされました。続行の準備ができました。",
      },
      creatives: {
        all: "すべて",
        accepteds: "承認済み",
        rejecteds: "拒否済み",
        pendings: "保留中",
        pending: "保留中",
        accepted: "承認済み",
        rejected: "拒否済み",
        adModalTitle: "広告プレビュー",
        creativeModalTitle: "クリエイティブプレビュー",
        creative: "クリエイティブ",
        name: "クリエイティブ名",
        search: "クリエイティブ内を検索",
        rejectSubtitle:
          "クリエイティブを拒否する理由を入力するか、空白のまま続行できます。",
        note: "クリエイティブを拒否する理由を入力してください。",
      },
      datasourceDetails: {
        entity: "エンティティ:",
        import: "インポートタイプ:",
        cycle: "インターバルサイクル:",
        url: "Feed URL:",
        success: "成功",
        failure: "失敗",
        in_progress: "進行中",
        partial_success: "部分的な成功",
      },
      generalInfo: {
        title: "一般情報",
        subtext: "マーケットプレイスに関する一般情報",
        market: "マーケットプレイス名",
        marketText:
          "インターフェースに表示される店舗名です。この情報を変更するには、アカウントマネージャーにお問合せください。",
        url: "URL",
        urlText:
          "インターフェースやリンク先で使用される店舗のリンクです。この情報を変更するには、アカウントマネージャーにお問合せください。",
        country: "国",
        countryText:
          "都市ターゲティングに使用される国です。この情報を変更するには、アカウントマネージャーにお問合せください。",
        zone: "タイムゾーン",
        zoneText:
          "インターフェースで使用されるデフォルトのタイムゾーンです。この情報を変更するには、アカウントマネージャーにお問合せください。",
        currency: "通貨",
        currencyText:
          "インターフェースで使用される通貨。この情報は変更できません。",
        zoneIstanbul: "ヨーロッパ/イスタンブール",
        email: "サポート用メールアドレス",
        emailText: "販売者が連絡するためのメールアドレスです。",
        emailLabel: "メール",
        emailError: "メールアドレスを入力する必要があります。",
        seller: "販売者パネル",
        sellerText:
          "販売者がインターフェースから直接パネルにアクセスできるようにするアドレスです。",
        sellerLabel: "販売者パネル",
        marketError: "マーケットプレイスの名前を入力する必要があります。",
        urlError: "URLを入力する必要があります。",
      },
      inspect: {
        campaign: "キャンペーン：",
        advertiser: "広告主：",
        url: "URL：",
        size: "サイズ：",
        status: "ステータス：",
        keywords: "キーワード",
        categories: "カテゴリー",
        languages: "言語:",
        regions: "地域:",
      },
      integrationInfo: {
        title: "統合情報",
        subtext: "データ転送を実行するために必要な統合情報",
        api: "APIセキュリティーコード",
        apiText:
          "プラットフォームにAPI経由でアクセスするために必要なセキュリティーコードです。",
        ftp: "FTPセキュリティー証明書",
        ftpText: "FTPサーバーにアクセスするために必要な証明書です。",
        download: "ダウンロード",
        copy: "クリックしてコピーします。",
        copied: "コピーしました",
        auto: "自動インプレッション",
        autoText:
          "すべてのインプレッションをイベントの統合なしに自動で記録する設定です。この設定がオンの場合、広告応答で返されたすべての製品がサイトに表示されたとみなし、各製品に対して表示の指標が記録されます。設定がオフの場合、各表示に対して希望する形式でイベントリクエストを送信する必要があります。",
        link: "インプレッションイベント",
        apiLink: "APIエンドポイント",
      },
      transactions: {
        subtext: "広告主がキャンペーンの費用として使用する残高を設定できます。",
        warning: "請求書番号を追加できます。",
        balance_updated: "残高支払い",
        gift_coupon: "ギフトクーポン",
        welcome_coupon: "ウェルカムクーポン",
        refund: "払い戻し",
        spend: "広告費用",
        ad_balance_loaded_desc: "広告残高を追加しました。",
        spend_desc: "広告費用が使われました。",
        gift_coupon_desc: "ギフトクーポンを獲得しました。",
        welcome_coupon_desc: "ウェルカムクーポンを獲得しました。",
        ad_balance_refunded_desc: "広告残高が払い戻しされました。",
        credit_card_desc: "クレジットカードで残高が更新されました。",
        detailsSubtext: "広告費用の詳細を確認できます。",
        spendTab: "広告費用",
        budgetTab: "予算リソース",
        advertiser_budget: "残高",
        approve: "残高の追加を続行しますか？",
        advertiser: "広告主：",
        balance: "「追加」される残高：",
        update: "請求書番号を追加または編集できます。",
        title: "請求書情報",
        payment: "支払い",
        success: "成功",
        waiting: "待機中",
        failure: "失敗",
        invoiceWarning: "請求書番号を最大30文字まで入力できます。",
        note: "残高アップロードノート",
        noteHelp: "この残高をアップロードする理由を含めます。例：8月の残高",
      },
      users: {
        label: "メール",
        subtext: "招待状の確認メールが送信されます。",
        invite: "招待状が送信されるメールアドレスを入力します。",
        warn: "自分のアカウントを停止することはできません。",
        error: "既存のメールアドレスを追加しました。",
        search: "ユーザー内を検索",
        select: "追加するユーザーのタイプを選択します。",
        advertiser: "広告主",
        advertiserText:
          "選択した広告主アカウントのみがアクセスでき、広告活動を実行する人物です。",
        marketplace: "管理者",
        marketplaceText:
          "広告プラットフォーム内のすべての権限を持ち、ユーザーへのアクセス権を付与または管理できます。",
        limited: "制限付きアクセス",
        full: "フルアクセス",
      },
      invited: {
        error:
          "同じアカウントに対して同じメールアドレスに招待状を送信できるのは、10分に1回までです。",
        success: "ユーザーが再度招待されました。",
        search: "招待されたユーザー内を検索",
        title: "招待状の詳細",
        info: "一般情報",
        mail: "メール：",
        advertiser: "広告主：",
        urlTitle: "招待リンク",
        link: "広告主ユーザーを追加する方法は？",
        single: "シングルユーザー",
        multiple: "一括ユーザー",
        advertiserText: "広告主を選択",
        draggerTitle:
          "<strong>.CSV</strong>ファイルを選択して、一括メールをアップロード",
        draggerText: "またはここにドラッグ＆ドロップします。",
        csvError: "ファイルをアップロード中にエラーが発生しました。",
        csvInfo:
          "CSVファイルを使用して、複数のユーザーを異なる広告主アカウントに一括招待できます。CSVファイルの形式に関する情報は、以下のリンクからアクセスできます。",
        restriction: "制限付きアクセス",
        restrictionText:
          "このオプションをオンにすると、ユーザーにはレポート権限のみが与えられます。",
        edit: "編集",
        role: "役割：",
        MARKETPLACE: "管理者",
        MARKETPLACE_REPORTER: "管理者レポーター",
        ADVERTISER: "広告主",
        ADVERTISER_REPORTER: "広告主レポーター",
      },
      sync: {
        file: "ファイル",
        api: "API",
        feed: "フィード",
        products_created: "製品が作成されました。",
        products_updated: "製品が更新されました。",
        products_deactivated: "製品のアクティブ化が解除されました。",
        products_activated: "製品がアクティブ化されました。",
        missing_advertisers: "広告主が見つかりません。",
        advertisers_created: "広告主が作成されました。",
        advertisers_updated: "広告主が更新されました。",
        advertisers_deactivated: "広告主のアクティブ化が解除されました。",
        advertisers_activated: "広告主がアクティブ化されました。",
        categories_created: "カテゴリーが作成されました。",
      },
      placement: {
        add: "新しい広告配信面を追加",
        display: "ディスプレイ",
        product: "スポンサー製品",
        update: "広告配信面を更新",
        settings: "一般設定",
        settingsText: "広告配信面の一般設定",
        name: "広告配信面名",
        nameText: "この名前は、あなただけが見ることができます。",
        format: "広告形式",
        formatText: "広告配信面が表示される形式",
        sponsoredText: "関連するキーワードおよびカテゴリーにおける製品表示",
        displayText:
          "関連するキーワードおよびカテゴリーにおけるビジュアル広告表示",
        suggestedTitle: "推奨サイズ",
        suggestedText:
          "広告配信面の実際のサイズです。このサイズは広告主に表示されます。",
        suggestedError: "1サイズまで追加可能です。",
        acceptedTitle: "対応可能サイズ",
        acceptedText:
          "広告配信面内に表示される他の広告サイズです。次元は広告主によって表示されます。",
        acceptedError: "広告サイズを選択してください。",
        formatError: "形式を選択してください。",
        cross_sell: "クロスセル",
        crossText:
          "関連する製品詳細ページへの広告表示や、リストされた製品の広告表示が防止されます。",
        maxAds: "広告の最大数",
        maxAdsText: "広告配信面に配置できる広告の最大数です。",
        crossTitle: "広告ブロックの動作",
        crossSub:
          "広告配信面の実際のサイズです。このサイズは広告主に表示されます。",
        crossPlaceholder: "タイプを選択します。",
        crossProduct: "リスト製品のブロック",
        crossBlock: "広告ブロック",
        crossError: "動作を選択します。",
        targeting: "ターゲティング",
        targetingText:
          "広告配信面をターゲットにするプレースメントグループを選択します。",
        typeError: "ターゲティングオプションを選択してください。",
        targetingPlaceholder: "ターゲットプレースメント",
        search: "検索結果",
        category: "カテゴリーのページ",
        homepage: "ホームページ",
        detail: "製品詳細ページ",
        collection: "コレクション",
        minAds: "最小広告数",
        minAdsText: "配置が持つことができる最小の広告数です。",
        minBid: "最低入札額",
        minBidText: "これはこの配置に対する最低入札額です。",
        videoWarning: "ビデオ広告の広告の最大数は <strong>“1”</strong> です。",
        promoteProducts: "商品を宣伝する",
        promoteText:
          "製品プロモーションが有効な場合、1つの広告しか追加できません。",
      },
      restrictions: {
        category: "カテゴリー",
        keyword: "キーワード",
        categoryTitle: "カテゴリー別入札額＆ACoS",
        categoryText:
          "指定したカテゴリーでのみ有効になる最低入札額とターゲットACoSを入力できます。また、カテゴリーの関連性をカスタマイズすることもできます。このフィールドでの設定は、一般設定より優先されます。",
        keywordText:
          "指定したキーワードで広告の表示を制限できます。［排他的］タイプで制限された検索結果では、この単語を含む製品のみが広告されます。［ブロック］タイプで制限された検索結果には、広告が表示されません。",
        placeholder: "キーワードを検索",
        add: "新しいキーワードを追加",
        info: "一般情報",
        label: "キーワード",
        help: "検索結果を制限するキーワード",
        type: "制限タイプ",
        typeText: "キーワードを使用した検索結果に適用される制限タイプ",
        exclusive: "排他的",
        exclusiveText:
          "検索結果には、キーワードに一致する製品のみが広告として表示されます。",
        block: "ブロック",
        blockText: "すべての広告が検索結果でブロックされています。",
        all: "すべて表示",
        edited: "編集されたカテゴリーを表示",
        expand: "すべて展開",
        collapse: "すべて折りたたむ",
      },
    },
    auth: {
      title: "{{value}}広告プラットフォーム",
      accounts: {
        title: "アカウントを選択",
        text: "ログインするアカウントを選択します。",
        logout: "ログアウト",
      },
      login: {
        title: "ログイン",
        text: "お帰りなさい。情報を入力してください。",
        remember: "ログイン状態を保持",
        forget: "パスワードを忘れた方",
        button: "ログイン",
        mail: "メール",
        password: "パスワード",
        mailErr: "メールアドレスを入力してください。",
        passwordErr: "パスワードを入力してください。",
        incorrectPassword: "パスワードが間違っています。",
        incorrectPasswordText: "もう一度お試しください。",
        recaptcha: "reCAPTCHA 検証エラー。",
        recaptchaText: "もう一度お試しください。",
        notActive: "アカウントが停止されました。",
        notActiveText: "サポートチームにお問合せください。",
        multipleAccountErr: "新しいアカウントを作成中にエラーが発生しました。",
        notHaveAnAccount: "既存のアカウントをお持ちではない方",
        signup: "サインアップ",
      },
      signUp: {
        title: "サインアップ",
        text: "{{value}}さん、ようこそ",
        password: "パスワード",
        passwordErr: "パスワードを入力してください。",
        warning:
          "数字、句読点、大文字と小文字を含む少なくとも8文字のパスワードを入力してください。",
        already: "既存のアカウントをお持ちですか？",
        successBtn: "ログイン",
        success: "登録しました。",
        successText:
          "登録が、{{value}}のメールアドレスで完了しました。ログインボタンをクリックして、パネルを開きます。",
        help: "サポートが必要ですか？",
        err: "有効なパスワードを入力してください。",
        userExistErr:
          "このユーザーは既に存在します。アクセス可能なアカウントを表示するには、ログインしてください。",
        privacyErr:
          "サインアップを完了するには、すべての必要な同意書に同意してください。",
        error: "招待状が見つかりません。",
        errorText:
          "登録するには招待が必要です。{{value}}経由でアカウントマネージャーにお問合せください。",
        errorTextNoEmail:
          "登録するには招待が必要です。アカウントマネージャーにお問合せください。",
        errorBtn: "ホームページに戻る",
      },
      password: {
        title: "パスワードを忘れた方",
        text: "パスワードをリセットするリンクをお送りします。プラットフォームで使用するメールアドレスを入力してください。",
        mail: "メール",
        mailInputErr: "メールアドレスを入力してください。",
        reset: "パスワードをリセット",
        return: "ログインページに移動",
        error: "パスワードをリセットしたいメールアドレスを入力してください。",
        recaptchaError: "セキュリティ上の理由により、パスワードの再設定試行が失敗しました。ページを更新してもう一度お試しください。",
        check: "メールを確認",
        message: "{{value}}にパスワードリセットリンクを送信しました",
      },
      newPassword: {
        title: "新しいパスワードを設定",
        text: "新しいパスワードは、以前使用したパスワードとは異なる必要があります。",
        help: "数字、句読点、大文字と小文字を含む少なくとも8文字のパスワードを入力してください。",
        password: "パスワード",
        passwordInputErr: "パスワードを入力してください。",
        again: "パスワードを再入力",
        check: "パスワードをリセットしました",
        message: "設定した新しいパスワードでログインできます。",
        errTitle: "有効なパスワードを入力してください。",
      },
      welcome: {
        title: "ようこそ。",
        loginText:
          "アカウントをお持ちの場合は、ログインボタンをクリックして情報を入力できます。",
        loginBtn: "ログイン",
        signupText:
          "アカウントをお持ちでない場合は、以下のボタンをクリックして登録し、パスワードを設定してからアカウントにログインできます。",
        signupBtn: "サインアップ",
      },
    },
  },
  common: {
    save: "保存",
    selectAll: "すべて選択",
    search: "検索",
    edit: "編集",
    export: "エクスポート",
    remove: "削除",
    add: "追加",
    column: "列",
    archive: "アーカイブ",
    removeArchive: "アーカイブ解除",
    delete: "削除",
    filter: "フィルター",
    continue: "続行",
    cancel: "キャンセル",
    cancellation: "キャンセル",
    okay: "OK",
    create: "作成",
    goBack: "戻る",
    listed: "リスト済み",
    archived: "アーカイブ済み",
    invited: "招待者",
    exact: "完全一致",
    phrase: "フレーズ一致",
    broad: "広範一致",
    turkey: "トルコ",
    abd: "アメリカ",
    try: "トルコリラ",
    usd: "米ドル",
    iqd: "イラクディナール",
    twd: "台湾ドル",
    hkd: "香港ドル",
    krw: "韓国ウォン",
    jpy: "日本円",
    zone: "ヨーロッパ/イスタンブール",
    clear: "クリア",
    completed: "完了済み",
    notCompleted: "未完了",
    now: "今",
    checkAll: "すべて選択",
    keyword: "キーワード",
    negative: "ネガティブキーワード",
    tr: "トルコ語",
    en: "英語",
    day: "日",
    invite: "招待",
    accept: "承認",
    reject: "拒否",
    pageTotal: "「表示」する行数：",
    addUser: "ユーザーを追加",
    reinvite: "再招待",
    advertiser: "広告主",
    admin: "管理者",
    new_customer: "新規顧客",
    existing_customer: "既存の顧客",
    category_buyer: "カテゴリー購入者",
    invite_url: "招待リンクを表示",
    copy: "コピーを作成",
    or: "または",
    exit: "出口",
    docs: "統合と広告配信に関する情報については、API ドキュメントをご覧ください！",
    apply: "適用",
    days: {
      monday: "月曜日",
      tuesday: "火曜日",
      wednesday: "水曜日",
      thursday: "木曜日",
      friday: "金曜日",
      saturday: "土曜日",
      sunday: "日曜日",
    },
    languages: {
      arabic: "アラビア語",
      english: "英語",
    },
    table: {
      name: "名前",
      status: "アクティブ",
      state: "ステータス",
      agency_name: "代理店",
      type: "広告主のタイプ",
      resource_id: "販売者ID",
      last_login: "最終ログイン日",
      balance: "残高",
      sum_coupons_amount: "クーポン",
      total_balance: "総残高",
      impressions: "インプレッション",
      viewable_impressions: "表示可能なインプレッション",
      clicks: "クリック",
      CTR: "CTR",
      spend: "広告費",
      sale_amount: "販売額",
      direct_sale_amount: "直接販売額",
      indirect_sale_amount: "間接販売額",
      sale: "販売",
      direct_sale: "直接販売",
      indirect_sale: "間接販売",
      ACoS: "ACoS",
      CVR: "CVR",
      campaign_name: "名前",
      advertiser_name: "広告主",
      ad_type: "広告タイプ",
      start_date: "開始日",
      end_date: "終了日",
      budget_type: "予算",
      bid_type: "入札タイプ",
      fixed_bid: "入札額",
      custom_key: "ID",
      ad_format: "広告形式",
      max_ads: "最大広告数",
      recommended_size: "推奨サイズ",
      sizes: "サイズ",
      image_url: "クリエイティブ",
      policy_status: "ステータス",
      transaction_date: "日付",
      invoice_number: "請求書番号",
      transaction_type: "取引タイプ",
      description: "説明",
      created_by: "作成者",
      amount: "金額",
      spend_details: "詳細",
      note: "注意",
      expire_date: "有効期限",
      account_user_status: "アクティブ",
      user_email: "メール",
      account_user_role: "認証",
      user_last_login: "最終ログイン日",
      account_user_created_at: "作成日",
      email: "メール",
      account_role: "認証",
      created_at: "招待日",
      report_name: "名前",
      report_start_date: "開始日",
      report_end_date: "終了日",
      report_type: "レポートタイプ",
      report_delivery_frequency: "頻度",
      report_created_at: "作成日",
      text: "名前",
      minimum_bid: "最小CPC",
      minimum_cpm: "最小CPM",
      relevance: "関連性",
      restriction_type: "制限タイプ",
      row_count: "行数",
      message: "操作メッセージ",
      sync_type: "同期タイプ",
      file_name: "ファイル名",
      product: "製品",
      sku: "SKU",
      buybox: "Buy Box",
      city: "都市",
      age_range: "年齢",
      gender: "性別",
      platform: "プラットフォーム",
      customer: "顧客",
      category: "カテゴリー",
      keyword: "キーワード",
      match_type: "一致タイプ",
      bid: "入札額",
      category_volume: "インプレッション数",
      active: "アクティブ",
      paused: "一時停止中",
      invited: "招待済み",
      uninvited: "未招待",
      archived: "アーカイブ済み",
      on: "アクティブ",
      stopped: "一時停止中",
      open: "開く",
      closed: "閉じる",
      coupon: "クーポン",
      sync_log_created_at: "日付",
      category_text: "カテゴリー",
      keyword_text: "キーワード",
      creative_name: "名前",
      invoice: "請求書番号",
      acos: "ACoS",
      sum_click: "クリック数",
      sum_direct_sale: "直接販売",
      sum_direct_sale_amount: "直接販売額",
      sum_impression: "インプレッション数",
      sum_sale: "販売",
      sum_sale_amount: "販売額",
      sum_spend: "支出",
      spending_type: "支出分配",
      min_ads: "最小広告数",
      min_bid: "最小入札額",
      languages: "言語",
      video_start: "動画開始数",
      video_complete: "動画完了数",
      VCR: "動画完了率",
      entity: "エンティティ",
      import_method: "インポート方法",
      schedule_interval: "サイクル",
      finish_date: "終了日",
      id: "ID",
    },
    equality: {
      contains: "含む",
      not_contains: "含まない",
      equal: "等しい",
      not_equal: "等しくない",
      less_than: "未満",
      grater_than: "より大きい",
      greater_or: "以上",
      less_or: "以下",
      any_of: "いずれか",
      none_of: "いずれでもない",
    },
    filters: {
      campaignName: "名前",
      campaignStartDate: "開始日",
      campaignEndDate: "終了日",
      campaignStatus: "ステータス",
      campaignBudget: "予算",
      campaignBudgetType: "予算タイプ",
      campaignBidType: "入札タイプ",
      campaignMaxAcos: "最大ACoS",
      campaignFixedBid: "固定入札額",
      sumSale: "販売",
      sumSpend: "支出",
      ACoS: "ACoS",
      product: "製品",
      productCampaignStatus: "ステータス",
      sumClick: "クリック",
      CVR: "CVR",
      keywordCampaignStatus: "ステータス",
      keywordText: "キーワード",
      keywordMatchType: "一致タイプ",
      negativeKeywordCampaignStatus: "ステータス",
      negativeKeywordText: "ネガティブキーワード",
      negativeKeywordMatchType: "一致タイプ",
      cityName: "都市",
      cityCampaignStatus: "ステータス",
      ageRangeText: "年齢",
      ageRangeCampaignStatus: "ステータス",
      genderText: "性別",
      genderCampaignStatus: "ステータス",
      platformText: "プラットフォーム",
      platformCampaignStatus: "ステータス",
      customerTargetingText: "顧客",
      customerTargetingCampaignStatus: "ステータス",
      placementText: "広告配信面",
      placementCampaignStatus: "ステータス",
      reportName: "レポート名",
      reportStartDate: "開始日",
      reportEndDate: "終了日",
      reportType: "レポートタイプ",
      reportDeliveryFrequency: "頻度",
      reportCreatedAt: "作成日",
      agencyName: "代理店名",
      advertiserName: "広告主",
      advertiserStatus: "広告主のステータス",
      sumSaleAmount: "販売額",
      sumDirectSale: "直接販売",
      sumIndirectSale: "間接販売",
      sumDirectSaleAmount: "直接販売額",
      sumIndirectSaleAmount: "間接販売額",
      sumImpression: "インプレッション数",
      productBrand: "製品ブランド",
      CTR: "CTR",
      keyword: "キーワード",
      category: "カテゴリー",
      advertiserId: "広告主ID",
      agencyId: "代理店ID",
      name: "製品名",
      brand: "ブランド",
      retailer_taxonomy: "製品カテゴリー",
      advertiser_id: "広告主ID",
    },
    equalityValues: {
      ct: "含む",
      ni: "含まない",
      eq: "等しい",
      ne: "等しくない",
      in: "いずれか",
      notin: "いずれでもない",
      lt: "未満",
      gt: "より大きい",
      ge: "以上",
      le: "以下",
    },
    values: {
      ACTIVE: "アクティブ",
      PAUSED: "一時停止中",
      DAILY: "毎日",
      TOTAL: "合計",
      STANDART: "自動",
      FIXED: "固定",
      MAX_ACOS: "最大ACoS",
      WEEKLY: "毎週",
      MONTHLY: "毎月",
      EXACT: "完全一致",
      PHRASE: "フレーズ一致",
      BROAD: "広範一致",
    },
    month: {
      may: "5月",
      june: "6月",
      september: "9月",
      october: "10月",
      december: "12月",
    },
  },
};

export default jp;
