import { useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Table } from "antd";
import {
  convertEnumToFormattedString,
  localeLanguages,
} from "../../../utils/helpers";
import { ReactComponent as ClockOutlined } from "../../../assets/icons/clockOutlined.svg";
import { ReactComponent as EntityOutlined } from "../../../assets/icons/entityOutlined.svg";
import { ReactComponent as LinkOutlined } from "../../../assets/icons/linkOutlined.svg";
import { ReactComponent as UploadOutlined } from "../../../assets/icons/uploadOutlined.svg";
import { ReactComponent as CheckCircleFilled } from "../../../assets/icons/checkCircleFilled.svg";
import { ReactComponent as NotificationIconFilled } from "../../../assets/icons/notificationIconFilled.svg";
import { ReactComponent as QuestionCircleFilled } from "../../../assets/icons/questionCircleFilled.svg";
import ConfigProvider from "antd/lib/config-provider";
import useDatasourceFetch from "../../../hooks/useDatasourceFetch";
import useTableFetch from "../../../hooks/useTableFetch";
import RetailExportBottomBar from "../../../components/Bar/RetailExportBottomBar";
import RetailLogsDrawer from "../../../components/Drawer/RetailLogsDrawer";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailPaginationButtons from "../../../components/Button/RetailPaginationButtons";
import RetailTitle from "../../../components/Typography/RetailTitle";
import RetailText from "../../../components/Typography/RetailText";
import RetailSearchBar from "../../../components/Bar/RetailSearchBar";

import cm from "./style.module.scss";

const DatasourceDetailsPage = () => {
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();

  const id = pathname.substring(pathname.lastIndexOf("/") + 1);

  const { data } = useDatasourceFetch();

  const {
    data: tableData,
    setSort,
    setIsExported,
    setFormat,
    changePageSize,
    isLoading,
    search,
    setSearch,
  } = useTableFetch(`data_sources/${id}/history`, false, {
    start_date: "asc",
  });

  const [visible, setVisible] = useState(false);

  const [historyId, setHistoryId] = useState<number | null>(null);

  const showLogs =
    data?.data?.import_method === "GOOGLE_FEED_URL_SCHEDULER" ||
    data?.data?.import_method === "META_FEED_URL_SCHEDULER";

  const openDrawer = (historyId: number) => {
    setVisible(true);
    setHistoryId(historyId);
  };

  const tableExport = (value: "excel" | "pdf" | "csv") => {
    setFormat(value);
    setIsExported(true);
  };

  const switchIcon = (value: string) => {
    switch (value) {
      case "SUCCESS":
        return <CheckCircleFilled />;
      case "PARTIAL_SUCCESS":
        return <CheckCircleFilled />;
      case "FAILED":
        return <NotificationIconFilled />;
      case "IN_PROGRESS":
        return <QuestionCircleFilled />;
      default:
        break;
    }
  };

  const switchColor = (value: string) => {
    switch (value) {
      case "PARTIAL_SUCCESS":
        return "blue";
      case "FAILURE":
        return "red";
      case "IN_PROGRESS":
        return "yellow";
      default:
        return "green";
    }
  };

  const columns = (t: TFunction) => [
    {
      title: t("common.table.start_date"),
      dataIndex: "start_date",
      width: "30%",
    },
    {
      title: t("common.table.finish_date"),
      dataIndex: "finish_date",
      width: "30%",
    },
    {
      title: t("common.table.status"),
      dataIndex: "status",
      width: "30%",
      render: (value: string) =>
        value ? (
          <div className={`${cm.statusContainer} ${cm[switchColor(value)]}`}>
            <RetailText size="xxxs" weight="medium" family="poppins">
              {t(`pages.admin.datasourceDetails.${value.toLowerCase()}`)}
            </RetailText>
            {switchIcon(value)}
          </div>
        ) : (
          "-"
        ),
    },
    ...(showLogs
      ? [
          {
            title: "",
            dataIndex: "name",
            width: "10%",
            render: (_: string, records: any) => (
              <div
                className={`flex ${cm.cols}`}
                onClick={() => openDrawer(records.id)}
              >
                <RetailText
                  size="xs"
                  weight="medium"
                  family="poppins"
                  className={cm.logsText}
                >
                  {t("pages.admin.console.logs")}
                </RetailText>
                <LinkOutlined />
              </div>
            ),
          },
        ]
      : []),
  ];

  const headerFields = [
    {
      icon: <EntityOutlined />,
      label: t("pages.admin.datasourceDetails.entity"),
      value: t(`pages.admin.console.${data?.data?.entity?.toLowerCase()}`),
    },
    {
      icon: <UploadOutlined />,
      label: t("pages.admin.datasourceDetails.import"),
      value: t(
        `pages.admin.console.${data?.data?.import_method?.toLowerCase()}`
      ),
    },
    {
      icon: <ClockOutlined />,
      label: t("pages.admin.datasourceDetails.cycle"),
      value:
        data?.data?.schedule_interval &&
        t(
          `pages.admin.console.${convertEnumToFormattedString(
            data?.data?.schedule_interval
          )}`
        ),
    },
    {
      icon: <LinkOutlined />,
      label: t("pages.admin.datasourceDetails.url"),
      value: data?.data?.feed_url,
    },
  ];

  return (
    <RetailPageContainer>
      <header className={cm.header}>
        <RetailTitle level={2}>{data?.data?.name}</RetailTitle>
        <section className={cm.headerFieldWrapper}>
          {headerFields.map(
            (field, index) =>
              field?.value && (
                <div key={index} className={cm.headerField}>
                  <div className={cm.headerFieldLabel}>
                    {field.icon}
                    <RetailText size="xs" weight="bold">
                      {field.label}
                    </RetailText>
                  </div>
                  <RetailText
                    size="xs"
                    weight="medium"
                    className={cm.headerFieldValue}
                  >
                    {field.value}
                  </RetailText>
                </div>
              )
          )}
        </section>
      </header>

      <ConfigProvider locale={localeLanguages(i18n.language)}>
        <div className={`bordered-container ${cm.container}`}>
          <RetailSearchBar
            fullWidth
            placeholder={t("common.search")}
            onChange={({ target }) => setSearch(target.value)}
            value={search}
          />
          <Table
            dataSource={tableData ? tableData?.data?.records : []}
            columns={columns(t)}
            showSorterTooltip={false}
            loading={isLoading}
            pagination={{
              total: tableData?.data.total_records,
              showSizeChanger: true,
              showTotal: () => t("common.pageTotal"),
              itemRender(_, type, element) {
                return (
                  <RetailPaginationButtons type={type} element={element} />
                );
              },
            }}
            sortDirections={["descend", "ascend", null]}
            onChange={(pagination, filters, sorter: any) => {
              sorter.order !== undefined && sorter.order !== null
                ? setSort({
                    sortValue: sorter.field,
                    order: sorter.order === "ascend" ? "asc" : "desc",
                  })
                : setSort(null);
              changePageSize(pagination.current!, pagination.pageSize!);
            }}
            scroll={{ x: true }}
          />

          <RetailExportBottomBar tableExport={tableExport} />
        </div>
      </ConfigProvider>
      <RetailLogsDrawer
        visible={visible}
        setVisible={setVisible}
        id={id}
        historyId={historyId}
      />
    </RetailPageContainer>
  );
};

export default DatasourceDetailsPage;
