import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row } from "antd";
import { convertEnumToFormattedString } from "../../../utils/helpers";
import { ReactComponent as LinkOutlined } from "../../../assets/icons/linkOutlined.svg";
import RetailLogsDrawer from "../../../components/Drawer/RetailLogsDrawer";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailTable from "../../../components/Table/RetailTable";
import RetailLinkColumn from "../../../components/Column/RetailLinkColumn";
import RetailText from "../../../components/Typography/RetailText";

import cm from "./style.module.scss";

const DeveloperConsolePage = () => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [historyId, setHistoryId] = useState<number | null>(null);

  const navigate = useNavigate();

  const openDrawer = (historyId: number) => {
    setVisible(true);
    setHistoryId(historyId);
  };

  const renderColumns = (col: string, value: any) => {
    switch (col) {
      case "entity":
      case "import_method":
        return t(`pages.admin.console.${value?.toLowerCase()}`);
      case "schedule_interval":
        return value
          ? t(`pages.admin.console.${convertEnumToFormattedString(value)}`)
          : "-";
      default:
        return value ? value : "-";
    }
  };

  const columns = () => [
    {
      title: "",
      dataIndex: "id",
      render: (value: number, records: any) => (
        <div className={cm.cols}>
          <div className="flex" onClick={() => openDrawer(value)}>
            <RetailText
              size="xs"
              weight="medium"
              family="poppins"
              className={cm.logsText}
            >
              {t("pages.admin.console.logs")}
            </RetailText>
            <LinkOutlined />
          </div>
          {records.import_method !== "API" && (
            <div className="flex">
              <RetailLinkColumn
                value={t("pages.admin.console.history")}
                to={`/admin/console/${records.id}`}
                state={{
                  breadcrumb: true,
                  breadcrumbType: "console",
                  breadcrumbValue: records.name,
                }}
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const tableConfig = {
    url: "data_sources",
    isRelation: false,
    renderColumns,
  };

  const navigateToCreateDatasource = () => navigate("/admin/create-datasource");

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          tableConfig={tableConfig}
          button={{
            title: t("pages.admin.console.add"),
            onClick: navigateToCreateDatasource,
          }}
          columnsForAdditionalRendering={() => columns()}
          rowSelection={undefined}
        />
      </Row>
      {/* Logs Drawer */}
      <RetailLogsDrawer
        visible={visible}
        setVisible={setVisible}
        id={historyId}
      />
    </RetailPageContainer>
  );
};

export default DeveloperConsolePage;
