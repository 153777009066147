import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { Log } from "../../../utils/types";
import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "../../../assets/icons/rightWhiteOutlined.svg";
import { ReactComponent as Error } from "../../../assets/icons/dark/error.svg";
import { ReactComponent as Warning } from "../../../assets/icons/dark/warning.svg";
import { ReactComponent as Info } from "../../../assets/icons/dark/info.svg";
import "highlight.js/styles/vs2015.css";
import Highlight from "react-highlight";
import useApi from "../../../api";
import Empty from "../../Empty";
import RetailDrawer from "../RetailDrawer";
import RetailText from "../../Typography/RetailText";

import cm from "./style.module.scss";

const LogTab = ({
  type,
  active,
  onClick,
}: {
  type: { value: "ERROR" | "WARNING" | "INFO"; icon: JSX.Element };
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <section
      className={`${active ? cm.active : ""} ${cm.logTypeContainer}`}
      onClick={onClick}
    >
      {type.icon}
      <RetailText
        size="xs"
        weight="medium"
        family="poppins"
        className={cm.tabText}
      >
        {type.value}
      </RetailText>
    </section>
  );
};

const logTypes: {
  value: "ERROR" | "WARNING" | "INFO";
  icon: JSX.Element;
}[] = [
  {
    value: "ERROR",
    icon: <Error />,
  },
  {
    value: "WARNING",
    icon: <Warning />,
  },
  {
    value: "INFO",
    icon: <Info />,
  },
];

interface RetailLogsDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  id: null | number | string;
  historyId?: number | null;
}

const RetailLogsDrawer = ({
  visible,
  setVisible,
  id,
  historyId,
}: RetailLogsDrawerProps) => {
  const { t } = useTranslation();

  const [dataStates, setDataStates] = useState<{
    type: "ERROR" | "WARNING" | "INFO";
    logs: Log[];
    loading: boolean;
  }>({
    type: "INFO",
    logs: [],
    loading: false,
  });

  const [timestampsHistory, setTimestampsHistory] = useState<string[]>([]);

  const [currentTimestamp, setCurrentTimestamp] = useState<string | null>(null);

  const { api } = useApi();

  const fetchLogs = async (
    type: "ERROR" | "WARNING" | "INFO",
    id: number | string,
    end?: string | null
  ) => {
    setDataStates((prevState) => ({
      ...prevState,
      type,
      loading: true,
    }));

    try {
      const config = {
        log_type: type,
        limit: 50,
        ...(end && { end }),
        ...(historyId && { data_source_history_id: historyId }),
      };

      const response = await api.post(`data_sources/${id}/logs`, config);

      setDataStates((prevState) => ({
        ...prevState,
        logs: response?.data?.records || [],
        loading: false,
      }));

      setCurrentTimestamp(response?.data?.last_timestamp);

      return response?.data;
    } catch (error) {
      console.error("Error fetching logs:", error);
      setDataStates((prevState) => ({
        ...prevState,
        loading: false,
      }));
      return null;
    }
  };

  const handleNextPage = async () => {
    const response: any = await fetchLogs(
      dataStates.type,
      id as number,
      currentTimestamp
    );
    if (timestampsHistory.length === 0 && currentTimestamp)
      setTimestampsHistory([currentTimestamp]);

    if (response && response.last_timestamp) {
      setTimestampsHistory((prev) => [...prev, response.last_timestamp]);
      setCurrentTimestamp(response.last_timestamp);
    }
  };

  const handlePreviousPage = async () => {
    if (timestampsHistory.length > 2) {
      const newHistory = timestampsHistory.slice(0, -1);

      const previousTimestamp = newHistory[newHistory.length - 2];

      setTimestampsHistory(newHistory);

      await fetchLogs(dataStates.type, id as number, previousTimestamp);
    } else {
      setTimestampsHistory([]);
      setCurrentTimestamp(null);
      await fetchLogs(dataStates.type, id as number);
    }
  };

  const closeDrawer = () => {
    setVisible(false);
    setDataStates({
      type: "ERROR",
      logs: [],
      loading: false,
    });
    setCurrentTimestamp(null);
    setTimestampsHistory([]);
  };

  const handleLogTabClick = (typeValue: "ERROR" | "WARNING" | "INFO") => {
    if (typeValue === dataStates.type) return;
    fetchLogs(typeValue, id as number, null);
  };

  const renderLogs = (logs: Log[]) => {
    return logs.map((log, index) => {
      const combinedLog = {
        ...JSON.parse(log.message),
        timestamp: log.timestamp,
      };

      return (
        <section className={cm.log} key={index}>
          <RetailText
            size="xs"
            weight="medium"
            family="poppins"
            className={cm.logText}
          >
            <Highlight className="json">
              {JSON.stringify(combinedLog, null, 2)}
            </Highlight>
          </RetailText>
        </section>
      );
    });
  };

  useEffect(() => {
    if (visible && id) fetchLogs("ERROR", id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, id]);

  return (
    <RetailDrawer
      visible={visible}
      title={t("pages.admin.console.drawer")}
      onOk={closeDrawer}
      onClose={closeDrawer}
      width="90%"
      type="dark"
      className={cm.drawer}
      footer={null}
    >
      <section className={cm.logTypeWrapper}>
        {logTypes.map((type) => (
          <LogTab
            key={type.value}
            type={type}
            active={type.value === dataStates.type}
            onClick={() => handleLogTabClick(type.value)}
          />
        ))}

        <section
          className={`${cm.prev} ${
            timestampsHistory.length === 0 ? cm.disabled : ""
          }`}
          onClick={handlePreviousPage}
        >
          <LeftOutlined />
          <RetailText
            size="xs"
            weight="medium"
            family="poppins"
            className={cm.tabText}
          >
            {t("pages.admin.console.prev")}
          </RetailText>
        </section>
        <section className={cm.next} onClick={handleNextPage}>
          <RetailText
            size="xs"
            weight="medium"
            family="poppins"
            className={cm.tabText}
          >
            {t("pages.admin.console.next")}
          </RetailText>
          <RightOutlined />
        </section>
      </section>
      <section
        className={
          dataStates.logs.length > 0 && !dataStates.loading
            ? cm.logsWrapper
            : cm.logsWrapperEmpty
        }
      >
        {dataStates.loading ? (
          <Spin spinning={dataStates.loading} />
        ) : dataStates.logs.length > 0 ? (
          renderLogs(dataStates.logs)
        ) : (
          <Empty type="logs" className={cm.empty} />
        )}
      </section>
    </RetailDrawer>
  );
};

export default RetailLogsDrawer;
